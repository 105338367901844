import React from 'react';
import { moneyDisplay } from 'utils/money'

/**
 * @param {object} p
 * @param {number} p.children - A numeric value that will be converted to pretty display
 * @param {string=} p.zero - what value should represent zero or undefined
 */
export default function Money({children, zero='-'}) {
  const amount = +children || 0
  const money = !amount ? zero : moneyDisplay(children)
  
  return ( 
    <span className={'money'}>
        {money}
    </span>
  );
};

