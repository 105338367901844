/* eslint-disable */

//TODO:  Change this to not log on prod builds
const shouldLog = (level) => {
  return (level <= process.env.REACT_APP_LOG_LEVEL);
};

export const error = (method, data, message) => {
  if (shouldLog(1)) console.error({method, data, message, timestamp: Date()});
};

export const warning = (method, data, message) => {
  if (shouldLog(2)) console.warn({method, data, message, timestamp: Date()});
};

export const info = (method, data, message) => {
  if (shouldLog(3)) console.info({method, data, message, timestamp: Date()});
};

export const debug = (method, data, message) => {
  if (shouldLog(4)) console.info({method, data, message, timestamp: Date()});
};

const lg = {
  error, warning, info, debug
}

export default lg