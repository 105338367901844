import { User } from '@firebase/auth'
import { db } from 'api'
import { NotFoundError, RuntimeError, ValidationError } from 'components/common/Error'
import dayjs from 'dayjs'
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, Timestamp, where, writeBatch } from 'firebase/firestore/lite'
import { nanoid } from 'nanoid'
import { getSearchParams } from 'navigation'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { resetState, setPayoffCalculatorState, useStore } from 'store'


export function useCalculation() {
  const { calculationId } = getSearchParams()

  return useQuery<Calculation, QueryError>(['calculation', calculationId], async () => {
    if (!calculationId) return
    resetState()
    const ref = await getDoc(doc(db, 'calculations', calculationId))

    const data = ref.data() as Calculation
    if (!data) throw new NotFoundError('Sorry, the calculation was not found')
    const calculation = { calculationId: ref?.id, ...data }
    setPayoffCalculatorState(calculation)
    return calculation
  })
}

/**
 * Get the users calculations for the give business id and their email
 */
export function useCalculationsByEmail(email: string, businessId: string, user?: User) {
  return useQuery<Calculation[], QueryError>(['calculations', email, businessId], async () => {

    const whereStatements = [
      where('email', '==', email),
      
    ]
    if (user.email !== email) {
      whereStatements.push(where('viewable', '==', true))
      whereStatements.push(where('businessId', '==', businessId))
    }

    const ref = await getDocs(query(collection(db, 'calculations'), ...whereStatements))
    if (ref.empty) return []
    const calculations = ref.docs.map(d => ({ calculationId: d.id, ...d.data() }) as Calculation)
    return calculations
  }, { enabled: !!(businessId && user?.email) })
}

type SaveCalculationInput = {
  user: User
  calculation: Calculation
  business: BusinessPublic
}

export function useSaveCalculation() {
  const queryClient = useQueryClient()
  async function upsertCalculation(input: SaveCalculationInput) {
    const store = useStore.getState()
    const calculationId = input.calculation?.calculationId || nanoid()

    const calculation = {
      businessId: input.calculation?.businessId || input.business?.businessId,
      email: input.calculation?.email || input.user.email,
      name: input.calculation?.name || dayjs().format('MMMM D, YYYY h:mm A'),
      viewable: true,
      type: 'PAY_OFF',
      payments: store.payments,
      debts: store.debts,
      debtsDisplayOrder: store.debtsDisplayOrder,
      planDefinitions: store.planDefinitions,
      payoffCalculationMeta: store.planProcessor?.payoffCalculationMetadata || {},
      createTimestamp: input.calculation?.createTimestamp || Timestamp.now(),
      updateTimestamp: Timestamp.now()
    } as Calculation

    const batch = writeBatch(db)
    batch.set(doc(db, 'calculations', calculationId), calculation, { merge: true })
    // if (input.calculation) batch.set(doc(db, 'calculations_history', nanoid()), input.calculation)
    await batch.commit()

    return { calculationId, ...calculation }
  }

  function onSuccess(data: Calculation) {
    queryClient.setQueryData<Calculation>(['calculation', data.calculationId], (old) => ({ ...old, ...data }))

    queryClient.setQueryData<Calculation[]>(['calculations', data.email, data.businessId], (calculations = []) => {
      const foundIndex = calculations.findIndex(c => c.calculationId === data.calculationId)
      if (foundIndex !== -1) {
        calculations[foundIndex] = data
        return [...calculations]
      }

      calculations.push(data)
      return [...calculations]
    })

    toast.success('Your calculation was saved')

  }

  return useMutation<Calculation, QueryError, SaveCalculationInput>(upsertCalculation, { onSuccess })
}


type UpdateCalculationInput = {
  businessId: string
  email: string
  calculationId: string
  name: string
  description: string
  viewable: boolean
}
export function useUpsertCalculationSummary() {
  const queryClient = useQueryClient()

  async function upsertCalculation(input: UpdateCalculationInput) {
    if (!input.email) throw new RuntimeError('SYSTEM ERROR - Email address is required')
    if (!input.name) throw new ValidationError('A valid name is required')

    const { calculationId = nanoid(), ...updateData } = input
    if (!input.calculationId) updateData['createTimestamp'] = Timestamp.now()

    await setDoc(doc(db, 'calculations', calculationId), updateData, { merge: true })
    return { ...input, calculationId }
  }

  function onSuccess(data: UpdateCalculationInput) {
    queryClient.setQueryData<Calculation>(['calculation', data.calculationId], (c) => ({ ...c, ...data }))

    queryClient.setQueryData<Partial<Calculation>[]>(['calculations', data.email, data.businessId], (calculations = []) => {
      const foundIndex = calculations.findIndex(c => c.calculationId === data.calculationId)
      if (foundIndex === -1) {
        calculations.push(data)
      } else {
        calculations[foundIndex] = { ...calculations[foundIndex], ...data }
      }
      return [...calculations]
    })

    toast.success(`Calculation ${data.name} updated`)
  }

  return useMutation<UpdateCalculationInput, QueryError, UpdateCalculationInput>(upsertCalculation, { onSuccess })
}

type RemoveCalculationInput = { calculationId, email, businessId }
export function useRemoveCalculation() {
  const queryClient = useQueryClient()

  return useMutation<RemoveCalculationInput, QueryError, RemoveCalculationInput>(async ({ calculationId, email, businessId }) => {
    await deleteDoc(doc(db, 'calculations', calculationId))
    return { calculationId, email, businessId }
  }, {
    onSuccess: (data) => {
      toast.success(`Calculation removed`)
      queryClient.setQueriesData<Calculation[]>(['calculations', data.email, data.businessId], (calculations) => {
        return calculations.filter(c => c.calculationId !== data.calculationId)
      })
    }
  })
}

export function useCopyCalculation() {
  const queryClient = useQueryClient()

  async function copyCalculation(calculation: Calculation) {
    const { calculationId: copiedFromId, ...newCalculation } = { ...calculation }
    const calculationId = nanoid()
    newCalculation.name = calculation.name + ' - Copy'
    await setDoc(doc(db, 'calculations', calculationId), newCalculation)
    return { calculationId, copiedFromId, ...newCalculation }
  }

  function onSuccess(data: Calculation) {
    queryClient.setQueryData<Calculation>(['calculation', data.calculationId], data)

    queryClient.setQueryData<Partial<Calculation>[]>(['calculations', data.email, data.businessId], (calculations = []) => {
      return [...calculations, data]
    })

    toast.success(`Calculation ${data.name} created`)
  }

  return useMutation<Calculation, QueryError, Calculation>(copyCalculation, { onSuccess })
}