import { useState } from 'react'

/**
 * @template T
 * 
 * @typedef useInputFormReturn
 * @property {T} data - current form data
 * @property {boolean} isDirty - true if the current state of the form is different than the default state
 * @property {function(React.SyntheticEvent):void} onChange - input on change event, and will update the state by target name
 * @property {function(React.SyntheticEvent):void} onSubmit - calls the input CB-Function with the data
 * @property {function(Partial<T>):void} updateState - sets the state equal to the data passed in
 * @property {function(T | import('react').SyntheticEvent=):void} resetState - If new state is provided it is set to that, otherwise it is set to the initial state passed in
 */ 

 /**
 * @template k
 * @param {k} [defaultState] - initial/default state of the form
 * @param {function(*):*} [formSubmitCb] - callback function called onSubmit with the current state of the data
 * @return {useInputFormReturn<k>}
 */
function useInputForm(defaultState, formSubmitCb = defaultFormSubmit) {
  const [initialState, setInitialState] = useState({ ...defaultState })
  const [data, setData] = useState({ ...defaultState })
  const [isDirty, setIsDirty] = useState(false)

  function onChange(e) {
    e.preventDefault()
    
    const newState = { ...data, [e.target.name]: e.target.value }
    setIsDirty(JSON.stringify(initialState) !== JSON.stringify(newState))
    setData(newState)
  }

  function onSubmit(e) {
    e.preventDefault()
   formSubmitCb(data)
  }

  function updateState(updatedData) {
    const newState = { ...data, ...updatedData }
    setData(newState)
    setIsDirty(JSON.stringify(initialState) !== JSON.stringify(newState))
  }

  function resetState(newState) {
    if (newState && 'nativeEvent' in newState) newState = null
    const state = newState ? newState : initialState
    setInitialState(state)
    setData(state)
    setIsDirty(false)
  }


  return {
    data,
    isDirty,
    onChange,
    onSubmit,
    resetState,
    updateState
  }
}

export default useInputForm

function defaultFormSubmit() {
  throw new Error('No formSubmitCb function was provided')
}