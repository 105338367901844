import CallToAction from 'components/common/CallToActionMessage'
import React from 'react'
import styleSynthesizer from 'styles'
import s from './footer.module.css'

const ss = styleSynthesizer(s, 'Footer')

export default function Footer({ business }: { business: BusinessPublic }) {

  return (
    <footer className={ss('container')}>
      <CallToAction className={s.message} business={business} />
      <p className={s.copyright}>&copy; Copyright 2021 myDebtFree.coach</p>
    </footer>
  )
};

