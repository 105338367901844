import { useBusiness } from 'api/business'
import HeaderMessage from 'components/common/HeaderMessage'
import React, { useState } from 'react'
import styleSynthesizer from 'styles'
import PaymentForm from './PaymentForm'
import s from './paymentSchedule.module.css'
import PaymentsList from './PaymentsList'


const ss = styleSynthesizer(s, 'PaymentSchedule')

export default function PaymentSchedule() {
  const business = useBusiness()
  const [ paymentToEdit, setPaymentToEdit ] = useState(null)

  return (
    <section aria-label="Payment Schedule" className={ss("container")}>
      <HeaderMessage title='Payment Schedule'> {business.data?.paymentScheduleMessage} </HeaderMessage>
      <div className={s.form}>
        <PaymentForm paymentToEdit={paymentToEdit} />
      </div>

      <div className={s.views}>
        <section aria-label="Payments List" className={s.payment}>
          <PaymentsList onEditPayment={setPaymentToEdit} />
        </section>
      </div>
    </section>
  )
};

