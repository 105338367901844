import { faEye, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLoggedInUser } from 'api/auth'
import { useBusiness } from 'api/business'
import { useCalculationsByEmail, useRemoveCalculation, useUpsertCalculationSummary } from 'api/calculations'
import { TIMESTAMP_FORMAT } from 'components/common/constants'
import asModel from 'components/common/Model'
import { Button, Input } from 'components/Forms'
import Checkbox from 'components/Forms/Checkbox'
import Loading from 'components/Loading'
import dayjs from 'dayjs'
import useInputForm from 'hooks/useFormManagement'
import { addSearchParam } from 'navigation'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import styleSynthesizer from 'styles'
import s from './savedCalculations.module.scss'
const ss = styleSynthesizer(s, 'SavedCalculations')

export default function SavedCalculations({ onClose }) {
  const user = useLoggedInUser()
  const business = useBusiness()
  const calculations = useCalculationsByEmail(user?.email, business.data?.businessId, user)
  if (calculations.isLoading) return <Loading />
  if (calculations?.data?.length <= 0) return <p className={s.noCalculations}>No Saved Calculations</p>

  return (
    <div className={ss('container')}>
      {calculations.data?.map(calculation =>
        <CalculationSummaryForm
          key={calculation.calculationId}
          calculation={calculation}
          onView={onClose} />)}
    </div>
  )
};

type FormProps = {
  calculation: Calculation,
  onView: () => void
}
function CalculationSummaryForm({ calculation, onView }: FormProps) {
  const updateCalculationSummary = useUpsertCalculationSummary()
  const removeCalculation = useRemoveCalculation()

  const { data, ...form } = useInputForm(getCalculationSummary(calculation), updateCalculationSummary.mutate)
  useEffect(() => { form.resetState(getCalculationSummary(calculation)) }, [calculation])

  function handleView() {
    onView()
    addSearchParam('calculationId', calculation.calculationId).navTo()
    toast.success(`Viewing ${calculation.name} calculation`)
  }

  function handleRemoval() {
    removeCalculation.mutate({ calculationId: calculation.calculationId, email: calculation.email, businessId: calculation.businessId })
  }

  return (
    <form className={ss('form')} onSubmit={form.onSubmit}>
      <fieldset className={s.calcRow}>
        <Input
          className={s.name}
          name='name'
          autoComplete="off"
          label={`Calculation Name`}
          type='text'
          value={data.name}
          onChange={form.onChange} />

        <Input
          className={s.description}
          name='description'
          autoComplete="off"
          label='Description'
          type='text'
          value={data.description}
          onChange={form.onChange} />

        <Checkbox
          label='Visible to a coach'
          name='viewable'
          value={data.viewable}
          onChange={form.onChange}
        />
        <p className={s.timestamp}>{dayjs(calculation.createTimestamp.toDate()).format(TIMESTAMP_FORMAT)}</p>
      </fieldset>
      <div className={s.actions}>
        <Button className={s.buttonSave} onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={updateCalculationSummary.isLoading} type='submit'>
          <FontAwesomeIcon icon={faSave} /> Save
        </Button>
        <Button className={s.buttonView} onClick={handleView} >
          <FontAwesomeIcon icon={faEye} /> View
        </Button>
        <Button className={s.buttonDelete} onClick={handleRemoval} isBlocking={removeCalculation.isLoading} >
          <FontAwesomeIcon icon={faTrashAlt} /> Remove
        </Button>

      </div>

    </form>
  )
}

function getCalculationSummary(calculation: Calculation) {
  return {
    calculationId: calculation.calculationId,
    businessId: calculation.businessId,
    email: calculation.email,
    name: calculation.name,
    description: calculation.description ?? '',
    viewable: calculation.viewable
  }
}

export const SavedCalculationsModel = asModel(SavedCalculations)
