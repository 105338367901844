import { auth, db } from 'api'
import { RuntimeError, ValidationError } from 'components/common/Error'
import { createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, User, UserCredential } from 'firebase/auth'
import { doc, Timestamp, writeBatch } from 'firebase/firestore/lite'
import { nanoid } from 'nanoid'
import { addSearchParam } from 'navigation'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { resetState } from 'store'

type Login = { email: string, password: string }
export function useLogin() {
  return useMutation<UserCredential, QueryError, Login>(async ({ email, password }) => {
    const user = await signInWithEmailAndPassword(auth, email.toLocaleLowerCase(), password)
    toast.success('Successfully logged in')
    return user
  })
}

type Signup = Login & { isBusinessProspect: boolean, fullName: string, businessId:string, businessUrl: string }
export function useSignup() {

  return useMutation<UserCredential, QueryError, Signup>(async ({ email: inEmail, password, isBusinessProspect, fullName, businessId, businessUrl }) => {
    const email = inEmail.replace(/^\W*|$\W/, '').trim().toLocaleLowerCase()
    if (!businessUrl) throw new RuntimeError('Business URL was not passed in')
    if (!businessId) throw new RuntimeError('Business ID was not passed in')
    if (!fullName) throw new ValidationError('Name is required')
    
    const userCred = await createUserWithEmailAndPassword(auth, email, password)
    await sendEmailVerification(auth.currentUser)

    const batch = writeBatch(db)

    const leads = doc(db, 'lead_events', nanoid())
    batch.set(leads, { businessId, isBusinessProspect, sourceEvent: 'Signup', fullName, email, source: businessUrl, registrationTimestamp: Timestamp.now() })

    await batch.commit()

    toast.success('Successfully signed up')
    return userCred
  })
}

export function logout() {
  resetState()
  auth.signOut()
  toast.success('Successfully Logged out')
}

type Email = string
export function usePasswordRest() {
  return useMutation<void, QueryError, Email>(async (email) => {
    const user = await sendPasswordResetEmail(auth, email, {
      url: addSearchParam('auth', 'login').fullUrl(),
      handleCodeInApp: false
    })
    toast.success('Email sent to ' + email)
    return user
  })
}

type UserWithStatus = User & {
  isLoading: boolean
  isSettled: boolean
  isLoggedIn: boolean
}

export function useLoggedInUser() {
  const [user, setUser] = useState<UserWithStatus>({
    ...auth.currentUser,
    isLoading: auth.currentUser ? false : true,
    isSettled: false,
    isLoggedIn: !!auth.currentUser
  })
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser({ ...user, isLoading: false, isSettled: true, isLoggedIn: !!user })
    })
  }, [])
  return user
}

export function userErrorMessage(error) {
  if (!error) return ''

  const { code } = error
  if (code === 'auth/missing-email') return 'Sorry, but an email address is required'
  if (code === 'auth/missing-password') return 'Sorry, but a password is required'
  if (code === 'auth/email-already-in-use') return 'Sorry, that email is already in use, please login'
  if (code === 'auth/user-not-found') return 'Sorry, we can not find that email'
  if (code === 'auth/wrong-password') return 'Sorry, that was the wrong password'
  if (code === 'auth/invalid-email') return 'Sorry, but that is an invalid email'
  if (code === 'auth/weak-password') return 'Sorry, but the password is a little too weak.  Must be over 6 characters'
  if (code === 'VALIDATION') return error.message
  if (code) return 'Sorry, there were issues logging in, please try again'
}