import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import __ from 'styles/buttons.module.css'

export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  children?: React.ReactNode | string
  className?: string
  isDirty?: boolean | undefined
  isBlocking?: boolean | undefined
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(({ className = '', children = 'Submit', isDirty = true, isBlocking = false, disabled, ...inputProps }, ref) => {
  const disabledResult = disabled ? disabled : !isDirty || isBlocking

  return (
    <button ref={ref} type="button" className={`${className} ${__.button}`}  disabled={disabledResult} {...inputProps} >
      {isBlocking &&
        <FontAwesomeIcon role='status' arial-label='processing' className={`${__.spinner} spinner`}
          data-testid="loading-spinner" icon={faSpinner} spin={true} />
      }
      {children}
    </button>
  ) as JSX.Element
})