import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@reach/router'
import { useBusiness } from 'api/business'
import React from 'react'
import { useStore } from 'store'
import styleSynthesizer from 'styles'
import s from './businessLogo.module.scss'
const ss = styleSynthesizer(s, 'BusinessLogo')

export default function BusinessLogo() {
  const businessName = useStore((state) => state.nav?.businessName)
  const business = useBusiness(businessName)

  if (businessName && business.isFetching) return <div className={ss('placeholder')}>
    <FontAwesomeIcon icon={faSpinner} spin={true} />
  </div>


  if (business.data.website.toLocaleLowerCase().includes('http') ||
    business.data.website.toLocaleLowerCase().includes('https')) {
    return (
      <a href={business.data.website}>
        <img className={ss('image')} style={{ minHeight: '150px', minWidth: '150px' }} src={business.data.logoUrl} alt="Business Logo" loading="eager" />
      </a>
    )
  }


  return (
    <Link to={business.data?.website || '/'}>
      <img className={ss('image')} style={{ minHeight: '150px', minWidth: '150px' }} src={business.data.logoUrl} alt="Business Logo" />
    </Link>
  )
}