export function penniesDisplay(pennies) {
  return moneyDisplay(pennies / 100)
}

export function moneyDisplay(money) {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return formatter.format(money)
}

export function penniesToMoney(pennies) {
  return pennies/100
}