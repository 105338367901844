import styleSynthesizer from 'styles'
import s from './ieMessage.module.scss'
const ss = styleSynthesizer(s, 'IeMessage')

export default function IeMessage() {
  return (
    <div className={ss('ieMessage')}>
      For the best experience please use the latest version of Chrome, Firefox, Safari, or Edge!
    </div>
  )
}