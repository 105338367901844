import { faArrowUp, faAt, faCommentDollar, faEnvelopeOpenText, faEye, faEyeSlash, faPencil, faPhone, faQuestion, faThumbsDown, faThumbsUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const CloseIcon = (): JSX.Element => <FontAwesomeIcon icon={faTimesCircle} />

export const HideCloseEye = ({ hide, hideText = "hide", showText = "show" }): JSX.Element => <React.Fragment>
  {hide
    ? <><FontAwesomeIcon icon={faEyeSlash} /> {hideText} </>
    : <><FontAwesomeIcon icon={faEye} />{showText} </>}
</React.Fragment>



export const ConnectTypeIcon = ({ contactType }: { contactType: ContactType }) => {
  let icon = faQuestion
  if (contactType === 'EMAIL') icon = faAt
  if (contactType === 'MAIL') icon = faEnvelopeOpenText
  if (contactType === 'PHONE') icon = faPhone
  if (contactType === 'TEXT') icon = faCommentDollar
  return <FontAwesomeIcon icon={icon} />
}

export const SuccessIcon = ({ success, ...attributes }: { success: boolean }) => <React.Fragment>
  {success
    ? <FontAwesomeIcon icon={faThumbsUp} data-tooltip='Connected' {...attributes} />
    : <FontAwesomeIcon icon={faThumbsDown} data-tooltip='Did Not Connect' {...attributes} />
  }
</React.Fragment>

export const EditIcon = (): JSX.Element => <FontAwesomeIcon icon={faPencil} />
export const UpArrow = (): JSX.Element => <FontAwesomeIcon icon={faArrowUp} />