import dayjs from 'dayjs'
import { nanoid } from 'nanoid'

type PaymentWithYearMonth = Payment & {
  yearMonth: number 
}

class PaymentSchedule { 
  payments: PaymentWithYearMonth[]

  constructor(payments: Payment[]) {
    this.payments = payments
      .map(p => ({ ...p, yearMonth: +p.year.concat(p.month) }))
      .sort((p1, p2) => p1.yearMonth - p2.yearMonth)
  }

  getAmountByMonthIteration(nMonth: number): number {
    const date = dayjs().add(nMonth + 1, 'month')
    const year = date.format('YYYY')
    const month = date.format('MM')
    const yearMonth = +year.concat(month)
    return this.getAmountByYearMonth(yearMonth, year, month)
  }

  getAmountByYearMonth(yearMonth: number, year: string, month: string): number {
    return this.payments
      .filter(p => (p.yearMonth <= yearMonth && p.frequency === 'PER_MONTH') ||
        (p.yearMonth === yearMonth && p.frequency === 'ONE_TIME') ||
        (p.month === month && +p.year <= +year && p.frequency === 'PER_YEAR'))
      .reduce((amount, p) => amount + (+p.amount), 0)
  }

  /**
   * @returns {Payment[]}
   */
  getPaymentsByDate() {
    return this.payments.map(payment => {
      return { ...payment, total: this.getAmountByYearMonth(payment.yearMonth, payment.year, payment.month) }
    })
  }


  getPayments() {
    return this.payments
  }
}

export default PaymentSchedule

export function createPayment(amount=0, nMonth = 0, frequency = 'PER_MONTH') {
  return {
    id: nanoid(8),
    month: dayjs().add(nMonth + 1, 'month').format('MM'),
    year: dayjs().format('YYYY'),
    frequency,
    amount
  }
}