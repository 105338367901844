export function flattenObjectMap(object = {}, keyName: string = 'id'): unknown {
  const flattened = Object.keys(object).map(k => ({ [keyName]: k, ...object[k] }))
  return flattened
}

export function inflateObjectMap(objects = [], keyName: string): unknown {
  return objects.reduce((objectArray, object) => {
    objectArray[object[keyName]] = { ...object }
    delete objectArray[object[keyName]][keyName]
    return objectArray
  }, {})
}