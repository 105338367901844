import { initializeApp  } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore/lite"
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectStorageEmulator , getStorage } from "firebase/storage";



const app = initializeApp({
  apiKey: "AIzaSyCUttgwkEbefLnhj93PsihwSUlZ_JZkq5w",
  authDomain: "debt-free-coach.firebaseapp.com",
  projectId: "debt-free-coach",
  storageBucket: "debt-free-coach.appspot.com",
  messagingSenderId: "729534527569",
  appId: "1:729534527569:web:bbc9a2e173def1472fe070",
  measurementId: "G-XB6NQMHE7Q"
})

export const analytics = getAnalytics(app);
export const db = getFirestore()
export const auth = getAuth()
export const storage = getStorage(app)

if(process.env.NODE_ENV !== 'production') {
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectAuthEmulator(auth, "http://localhost:9099")
  connectStorageEmulator(storage, 'localhost', 9199)
}

