import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useStore } from 'store'
import { removePlanDefinition } from 'store/planDefinitions'
import { setSelectedPlan } from 'store/userInteractions'
import styleSynthesizer from 'styles'
import PlanDetails from '../PlanDetails'
import PlanOverview from '../PlanOverview'
import PlansOverviewChart from '../PlansOverviewChart'
import s from './plansDisplay.module.scss'


const ss = styleSynthesizer(s, 'PlansDisplay')


export default function PlansDisplay() {
  const { amountDisplayType, plans, selectedPlanId } = useStore(state => ({
    amountDisplayType: state.userInteractions.amountDisplayType,
    plans: state.planProcessor.plans,
    selectedPlanId: state.userInteractions.selectedPlanId
  }))

  function handlePlanSelect(plan) {
    setSelectedPlan(plan)
    setTimeout(() => {
      window.document.getElementById('planOverview')?.scrollIntoView({ inline: 'center', behavior: "smooth" })
    }, 200)
  }
  return (
    <React.Fragment>

      <section id={'planOverview'} className={ss('planOverview')}>
        {plans.map((plan) =>
          <div key={plan.definition.id} className={s.plan}>
            <PlanOverview
              amountDisplayType={amountDisplayType}
              plan={plan}
              onPlanSelect={handlePlanSelect}
              onPlanRemove={(plan) => removePlanDefinition(plan.definition)} />
          </div>
        )}
      </section>

      {selectedPlanId &&
        <div className={s.detailClose}>
          <button onClick={() => setSelectedPlan(null)}><FontAwesomeIcon icon={faTimesCircle} /></button>
        </div>
      }

      {selectedPlanId
        ? <PlanDetails />
        : <PlansOverviewChart />
      }

    </React.Fragment>
  )
}