export class NotFoundError extends Error {
  code: string

  constructor(message) {
    super(message)
    this.code = 'NOT-FOUND'
  }
}

export class ValidationError extends Error {
  code: string
  constructor(message) {
    super(message)
    this.code = 'VALIDATION'
  }
}

export class RuntimeError extends Error {
  code: string
  constructor(message) {
    super(message)
    this.code = 'RUNTIME'
  }

}