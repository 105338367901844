import React, { useState } from 'react'
import __ from '../styles/inputs.module.css'


const Percent = (props, ref) => {
  const { className, label, value, helpText, ...otherProps } = props
  const [ type, setType ] = useState('text')


  return (
    <div className={`${className} input-container`}>
      <label className={`label ${__.label}`}>
        <span className={`label-text ${__.labelText}`}>{label}</span>
        <div className={`inputWrapper ${__.inputWrapper}`}>
          <input aria-label={label}
            className={`input ${__.input} `}
            ref={ref}
            onFocus={() => { if (type !== 'number') setType('number') }}
            onBlur={() => { if (type !== 'text') setType('text') }}
            step="any"
            type={type}
            value={type === 'text' && value && value > 0 ? `${value}%` : value || ''}
            {...otherProps} />
        </div>
      </label>
      <p className={__.helpText}>{helpText}</p>
    </div>
  )
}

export default React.forwardRef(Percent)