import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from '@reach/router'
import ForgotPassword from 'components/Auth/ForgotPassword'
import Login from 'components/Auth/Login'
import Signup from 'components/Auth/Signup'
import { getSearchParams, removeSearchParam } from 'navigation'
import React from 'react'
import styleSynthesizer from 'styles'
import s from './auth.module.scss'
const ss = styleSynthesizer(s, 'Auth')

export default function Auth() {
  useNavigate() //DO NOT REMOVE, used to watch for url changes
  const {auth} = getSearchParams()

  let Page = null
  if (auth === 'login') Page = <Login />
  if (auth === 'signup') Page = <Signup />
  if (auth === 'forgotPassword') Page = <ForgotPassword />

  if (Page) {
    document.getElementById('root').classList.add('model-open')
  } else {
    document.getElementById('root').classList.remove('model-open')
    return null
  }

  return (
    <div className={ss('container')} >
      <div className={s.page} >
        <button onClick={() => removeSearchParam('auth').navTo()} className={s.closeButton}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
        {Page}
      </div>
    </div>
  )
}