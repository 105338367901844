import React from 'react';
import s from './loadingOverlay.module.scss';
import styleSynthesizer from 'styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
const ss = styleSynthesizer(s, 'LoadingOverlay')

export default function LoadingOverlay({message}) {

  return ( 
    <div role='status' aria-label='loading'  className={ss('container')}>
        <FontAwesomeIcon className={s.icon} icon={faDollarSign} />
        <p className={s.message}>{message}</p>
    </div>
  );
};