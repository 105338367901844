import { getDownloadURL, ref, uploadBytes } from '@firebase/storage'
import { useParams } from '@reach/router'
import { auth, db, storage } from 'api'
import { defaultBusiness } from 'components/common/constants'
import { RuntimeError, ValidationError } from 'components/common/Error'
import { collection, doc, getDoc, getDocs, query, updateDoc, where, writeBatch } from 'firebase/firestore/lite'
import { nanoid } from 'nanoid'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { inflateObjectMap } from 'utils/objectMap'
import { validateBusinessRegistrationInput } from './validations/businessInfoValidation'

export function useBusiness(businessNameIn = '') {
  const businessName = useParams()?.businessName
  const queryName = businessNameIn ? businessNameIn?.toLocaleLowerCase() : businessName?.toLocaleLowerCase()

  return useQuery<BusinessPublic, QueryError>(['business_public', queryName], async () => {
    if (businessName === 'debtFreeCoach' || !queryName) return defaultBusiness

    const q = query(collection(db, 'business_public'), where("businessUrl", '==', queryName.toLocaleLowerCase()))
    const results = await getDocs(q)

    if (results.empty) return { ...defaultBusiness, businessUrl: 'UNKNOWN' }
    const business = results.docs[0].data() as BusinessPublic
    setCssVariables(business)
    return business
  }, {
    placeholderData: defaultBusiness,
    refetchOnMount: false,
    staleTime: 1000 * 60
  })
}

export function useBusinessById(businessId) {
  return useQuery<BusinessPublic, QueryError>(['business_public', businessId], async () => {
    if (!businessId) return
    const ref = await getDoc(doc(db, 'business_public', businessId))
    if (ref.exists()) return { businessId: ref.id, ...ref.data() } as BusinessPublic
  })
}

export function useBusinessPrivate(businessId) {
  return useQuery<BusinessInfo, QueryError>(['business', businessId], async () => {
    const ref = await (await getDoc(doc(db, 'business', businessId)))
    if (ref.exists()) return { businessId: ref.id, ...ref.data() } as BusinessInfo
  }, { enabled: !!businessId })
}


export function useRegisterBusiness() {
  const queryClient = useQueryClient()
  return useMutation<{ businessInfo: BusinessInfo, businessPublic: BusinessPublic }, QueryError, BusinessInfo>(async (businessInfo) => {
    const businessId = auth.currentUser.uid
    if (!businessId) throw new RuntimeError('APPLICATION ERROR - No userID on auth user')

    validateBusinessRegistrationInput(businessInfo)
    const businessPublic = generateInitialBusiness(businessInfo, businessId)
    const batch = writeBatch(db)
    batch.set(doc(db, 'business', businessId), businessInfo)
    batch.set(doc(db, 'business_public', businessId), businessPublic)
    batch.commit()

    return { businessInfo: { businessId, ...businessInfo }, businessPublic }
  }, {
    onSuccess: (data) => {
      queryClient.setQueryData(['business', data.businessInfo.businessId], data.businessInfo)
      queryClient.setQueryData(['business_public', data.businessInfo.businessId], data.businessPublic)
    }
  })
}

export function useUpdateBusinessPrivate() {
  const queryClient = useQueryClient()
  return useMutation<BusinessInfo, QueryError, BusinessInfo>(async (businessInfo) => {
    validateBusinessRegistrationInput(businessInfo)
    const batch = writeBatch(db)
    batch.set(doc(db, 'business', businessInfo.businessId), businessInfo, { merge: true })
    batch.set(doc(db, 'business_public', businessInfo.businessId), {
      businessName: businessInfo.businessName
    }, { merge: true })

    await batch.commit()
    toast.success('Business Updated')
    return businessInfo
  }, {
    onSuccess: (businessInfo) => {
      queryClient.setQueryData(['business', businessInfo.businessId], businessInfo)
      queryClient.setQueryData(['business_public', businessInfo.businessId], (old: BusinessPublic) => ({ ...old, businessName: businessInfo.businessName }))
    }
  })
}

export function useUpdateBusinessPublicInfo() {
  const queryClient = useQueryClient()
  return useMutation<BusinessPublic, QueryError, BusinessPublic>(async (businessInfo) => {
    const q = query(collection(db, 'business_public')
      ,where("businessUrl", '==', businessInfo.businessUrl.toLocaleLowerCase())
      ,where("businessId", '!=', businessInfo.businessId)
    )
    const results = await getDocs(q)
    if (!results.empty) throw new ValidationError('Business URL is already taken')

    const businessData = { ...businessInfo, businessUrl: businessInfo.businessUrl.toLocaleLowerCase() }
    delete businessData.logoUrl //Do not update the url logo
    await updateDoc(doc(db, 'business_public', businessInfo.businessId), businessData)
    toast.success('Business Updated')
    return businessInfo
  }, {
    onSuccess: (businessInfo) => {
      queryClient.setQueryData(['business_public', businessInfo.businessUrl], businessInfo)
    }
  })
}

type BusinessImageUploadInput = {
  businessId: string
  file: File
}

type BusinessImageUploadOutput = {
  businessId: string
  logoUrl: string
}

export function useUploadBusinessImage() {
  const queryClient = useQueryClient()
  return useMutation<BusinessImageUploadOutput, QueryError, BusinessImageUploadInput>
    (async ({ businessId, file }) => {
      if (file.size > 500 * 1000) throw new ValidationError('Sorry, image must be less than 500k')

      const fileRef = ref(storage, `images/${businessId}.${file.name.split('.').pop().toLocaleLowerCase()}`)
      await uploadBytes(fileRef, file)
      const logoUrl = await getDownloadURL(fileRef)

      await updateDoc(doc(db, 'business_public', businessId), { logoUrl })

      toast.success('Business Updated')
      return { businessId, logoUrl }
    }, {
      onSuccess: ({ businessId, logoUrl }) => {
        queryClient.setQueryData(['business_public', businessId], (old: BusinessPublic) => ({ ...old, logoUrl }))
      }
    })
}

interface LeadConfigInput {
  businessId: string
  statuses: FlatLabel[]
  sources: FlatLabel[]
  sourceEvents: FlatLabel[]
  tags: FlatLabel[]
  statusIds: Record<string, string>
  // statusIds: { [key: string]: string }
}
export function useUpdateLeadConfig() {
  const queryClient = useQueryClient()

  async function updateLeadConfig(input: LeadConfigInput) {
    const statusIds = input.statuses.reduce((ids, status) => {
      ids[status.id] = input.statusIds[status.id] || nanoid()
      return ids
    }, {})

    const leadConfig = {
      statuses: inflateObjectMap(input.statuses, 'id'),
      sources: inflateObjectMap(input.sources, 'id'),
      sourceEvents: inflateObjectMap(input.sourceEvents, 'id'),
      tags: inflateObjectMap(input.tags, 'id'),
      statusIds
    } as LeadConfig

    updateDoc(doc(db, 'business', input.businessId), { leadConfig })

    return leadConfig
  }

  function onSuccess(leadConfig: LeadConfig, input: LeadConfigInput) {
    queryClient.setQueryData<BusinessInfo>(['business', input.businessId], (data) => {
      data.leadConfig = { ...data.leadConfig, ...leadConfig }
      return { ...data }
    })

    toast.success('Lead Configuration Updated')
  }

  return useMutation(updateLeadConfig, { onSuccess })
}

function generateInitialBusiness(businessInfo: BusinessInfo, businessId): BusinessPublic {
  return {
    ...defaultBusiness,
    businessId,
    siteTitle: businessInfo.businessName,
    businessName: businessInfo.businessName,
    businessUrl: businessInfo.businessName.replace(new RegExp(/[^\w\d]/gm), '').toLocaleLowerCase()
  }
}

function setCssVariables(business: BusinessPublic) {
  const r = document.querySelector<HTMLElement>(':root')
  r.style.setProperty('--header-background-color', business.headerBackgroundColor)
  r.style.setProperty('--header-text-color', business.headerTextColor)
  r.style.setProperty('--footer-background-color', business.footerBackgroundColor)
  r.style.setProperty('--footer-text-color', business.footerTextColor)
  r.style.setProperty('--model-title-background-color', business.modelTitleBackgroundColor)
  r.style.setProperty('--model-title-text-color', business.modelTitleTextColor)
  r.style.setProperty('--section-information-color', business.sectionHeaderMessageColor)
  r.style.setProperty('--input-focus-color', business.inputFocusColor)
  r.style.setProperty('--label-color', business.labelColor)
  r.style.setProperty('--debt-totals-message-number', business.debtTotalsMessageNumberColor)
  r.style.setProperty('--quote-color', business.quoteColor)
  r.style.setProperty('--quote-citation-color', business.quoteCitationColor)
  r.style.setProperty('--button-background-color', business.buttonBackgroundColor)
  r.style.setProperty('--button-text-color', business.buttonTextColor)
}

