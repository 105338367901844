import { faBars, faBuilding, faCalculator, faFolderPlus, faSignOutAlt, faUndo, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from '@reach/router'
import { logout, useLoggedInUser } from 'api/auth'
import { useBusiness } from 'api/business'
import { defaultBusiness } from 'components/common/constants'
import { Button } from 'components/Forms'
import PopupMenu from 'components/PopupMenu'
import { SavedCalculationsModel } from 'components/SavedCalculations'
import { addSearchParam, removeSearchParam, navBuilder } from 'navigation'
import React, { useMemo, useState } from 'react'
import { resetState } from 'store'
import styleSynthesizer from 'styles'
import s from './primaryMenu.module.scss'
const ss = styleSynthesizer(s, 'PrimaryMenu')

export default function PrimaryMenu() {
  const user = useLoggedInUser()
  const business = useBusiness()
  const [showSavedCalculations, setShowSavedCalculations] = useState(false)
  const location = useLocation() as LocationWithState
  const returnUrl = useMemo(() => location.state?.returnUrl, [location.pathname])


  if (!user.isLoggedIn) return (
    <div className={ss('actions')} role='menu' aria-label='login' >
      <Link role="menuitem" tabIndex={-1} className={s.loginLink} to={addSearchParam('auth', 'login').url()}>
        <Button className={s.login} isBlocking={user.isLoading}>
          <FontAwesomeIcon icon={faUser} /> Login
        </Button>
      </Link>
    </div>
  )

  return (
    <React.Fragment >
      {showSavedCalculations && <SavedCalculationsModel title='Your Saved Calculations' onClose={() => setShowSavedCalculations(false)} />}
      {returnUrl && <Link to={returnUrl}>
        <button className={s.returnButton}>
          <FontAwesomeIcon icon={faUndo} /> <span className={s.returnText}>Return</span>
        </button>
      </Link>}

      <div role='menu' aria-label='primary menu' className={s.actions}>
        <PopupMenu icon={faBars}>
          <li data-type='title'>Calculations</li>
          <li role="menuitem" className={s.newCalc} >
            <button onClick={() => {
              resetState()
              removeSearchParam('calculationId').navTo()
            }}>
              <FontAwesomeIcon icon={faFolderPlus} />
              <span>New Calculation</span>
            </button>
          </li>

          <li role="menuitem" className={s.saved} >
            <button onClick={() => setShowSavedCalculations(true)}>
              <FontAwesomeIcon icon={faCalculator} />
              <span>Saved Calculations</span>
            </button>
          </li>

          {/* <li className={s.share} >
            <button>
              <FontAwesomeIcon icon={faShareAlt} />
              <span>Share Calculation</span>
            </button>
          </li> */}

          {/* <li className={s.history} >
          <button>
            <FontAwesomeIcon icon={faHistory} />
            <span>View History</span>
          </button>
        </li> */}

          <li role="menuitem" data-type='title'>Account</li>
          {user.uid === business.data?.businessId &&
            <li className={s.business}>
              <Link className={s.businessLink} to={navBuilder.business(business.data?.businessId).customers().landing().url()} >
                <FontAwesomeIcon icon={faBuilding} />
                <span>Business Page</span>
              </Link>
            </li>
          }
          <li role="menuitem" className={s.signOut}>
            <button onClick={() => {
              logout()
              business.data.businessId === defaultBusiness.businessId ? navBuilder.home().navTo() : navBuilder.homeBusiness(business.data.businessUrl).navTo()
            }}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>Sign Out</span>
            </button>
          </li>
        </PopupMenu>
      </div>
    </React.Fragment >
  )
}