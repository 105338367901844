import { useParams } from '@reach/router'
import { useLoggedInUser } from 'api/auth'
import { useBusiness } from 'api/business'
import { useCalculation } from 'api/calculations'
import ComponentError from 'components/common/ComponentError'
import { defaultBusiness } from 'components/common/constants'
import LoadingOverlay from 'components/common/LoadingOverlay'
import DebtsForm from 'components/Debts'
import DebtTotals from 'components/Debts/DebtTotals'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Payments from 'components/Payments'
import Plans from 'components/Plans'
import SaveButton from 'components/SaveButton'
import { addSearchParam, getSearchParams, navBuilder, removeSearchParam } from 'navigation'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { useStore } from 'store'
import styleSynthesizer from 'styles'
import s from './home.module.scss'
const ss = styleSynthesizer(s, 'Home')

export default function Home() {
  const { calculationId } = getSearchParams()
  const user = useLoggedInUser()
  const calculation = useCalculation()
  const business = useBusiness()
  const { businessName } = useParams()
  const { auth } = getSearchParams()

  if (businessName && business.isSuccess && business.data.businessUrl !== businessName && business.data.businessUrl !== defaultBusiness.businessUrl) navBuilder.notFound().navTo()
  if (calculationId && user.isSettled && !user?.uid && !auth) addSearchParam('auth', 'login').navTo({ message: 'Sorry, but you must be logged in to view an existing calculation' })
  if (calculationId && calculation.isError && user.uid) removeSearchParam('calculationId').navTo()


  useEffect(() => {
    if (calculationId && user.uid) {
      calculation.refetch()
    }
  }, [user.uid, calculationId])

  useEffect(() => {
    useStore.setState({ nav: { businessName } })
  }, [businessName])

  return (
    <div className={s.page}>
      <Helmet>
        <title>My Debt Free Coach</title>
      </Helmet>
      {(calculation.isLoading && calculationId) && <LoadingOverlay message='Loading Calculation' />}
      {(business.isFetching && businessName) && <LoadingOverlay message="One minute please, loading business specific page" />}
      <Header business={business.data} />
      <main className={ss('container')}>
        <React.Fragment>
          <ErrorBoundary fallback={<ComponentError componentName='Save Button' />}>
            <div>
              {calculation.data?.name && <p className={s.savedCalcName}>{calculation.data?.name} - {calculation.data?.description?.substring(0, 50)}...</p>}
              <SaveButton />
            </div>

          </ErrorBoundary>

          <div className={ss('debtsAndPayment')}>
            <ErrorBoundary fallback={<ComponentError componentName='Debts' />}>
              <DebtsForm />
            </ErrorBoundary>

            <ErrorBoundary fallback={<ComponentError componentName='Payments' />}>
              <Payments />
            </ErrorBoundary>
          </div>

          <ErrorBoundary fallback={<ComponentError componentName='Debt Totals' />}>
            <DebtTotals />
          </ErrorBoundary>

          <ErrorBoundary fallback={<ComponentError componentName='Plans Generation' />}>
            <Plans />
          </ErrorBoundary>
        </React.Fragment>
      </main>

      <Footer business={business.data} />
    </div>
  )
}