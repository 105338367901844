import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@reach/router'
import BusinessLogo from 'components/common/BusinessLogo'
import PrimaryMenu from 'components/PrimaryMenu'
import { useIsFetching } from 'react-query'
import styleSynthesizer from 'styles'
import s from './header.module.scss'
const ss = styleSynthesizer(s, 'Header')


export default function Header({ business }: { business: BusinessPublic }) {
  const isFetching = useIsFetching()
  return (
    <header className={ss('container')}>
      <div className={ss('content')}>
        {isFetching > 0 && <FontAwesomeIcon className={s.isFetching} icon={faSpinner} spin aria-live="polite" aria-label="saving" />}
        <div className={s.imageContainer}>
          <BusinessLogo />
        </div>
        <div className={s.titleContainer}>
          {window.location.pathname === "/about"
            ? <Link to='/' className={s.about}>Home</Link>
            : <Link to='/about' className={s.about}>About</Link>
          }

          <h1 className={s.title}>{business.siteTitle}</h1>
          <p className={s.message}>{business.tagLine}</p>
        </div>
        {/* <SocialMedia /> */}
      </div>
      <div className={ss('primaryMenu')}>
        <PrimaryMenu />
      </div>
    </header>
  )
};

