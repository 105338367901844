import ContrastColor from 'components/common/ContrastColor'
import { CloseIcon, HideCloseEye } from 'components/common/Icons'
import NMonth from 'components/common/nMonth'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { penniesDisplay } from 'utils/money'
import PlanDebtDetails from '../PlanDebtDetails'
import s from './planOverview.module.scss'


type props = {
  plan: Plan
  amountDisplayType: AmountDisplayId
  onPlanSelect: (plan: Plan) => void
  onPlanRemove: (plan: Plan) => void
}

export default function PlanOverview({ plan, amountDisplayType, onPlanSelect, onPlanRemove }: props) {
  const debtTotals = plan.summary.snapshot.debtTotals
  const [showDebtsSummary, setShowDebtsSummary] = useState(false)
  const [showDescription, setShowDescription] = useState(false)


  return (
    <div className={s.plan}>
      <div style={{ backgroundColor: plan.definition.color }} className={s.planName}>
        <p><ContrastColor hexColor={plan.definition.color}>{plan.definition.name}</ContrastColor></p>
        <button className={s.removePlan} onClick={() => onPlanRemove(plan)}>
          <ContrastColor hexColor={plan.definition.color} lightColor='white'><CloseIcon /></ContrastColor>
        </button>
      </div>

      <div className={s.contentWrapper}>
        <p className={s.amount}>
          {amountDisplayType === 'I' ? <span className={s.label}>Interest: </span> : <span className={s.label}>Out of Pocket:</span>}
          {amountDisplayType === 'I' ? penniesDisplay(debtTotals.totalInterest) : penniesDisplay(debtTotals.totalPayed)}
        </p>
        <p className={s.months}><span className={s.label}>Debt Free: </span> <NMonth>{plan.summary.debtFreeDate}</NMonth></p>
        <p className={s.length}>{yearMonthDisplay(plan.summary.debtFreeDate)}</p>
      </div>


      <div className={s.debtsSummary}>
        <div className={s.debtsSummaryHeader}>
          <span className={s.label}>Payoff Summary:</span>
          <button className={s.showDebtsSummaryButton} onClick={() => setShowDebtsSummary(!showDebtsSummary)}>
            <HideCloseEye hide={showDebtsSummary} />
          </button>
        </div>
        {showDebtsSummary && <PlanDebtDetails planSnapshot={plan.summary.snapshot} amountDisplayType={amountDisplayType} />}
      </div>

      <div className={s.planDescription}>
        <div className={s.planDescriptionHeader}>
          <span className={s.label}>Strategy Description:</span>
          <button className={s.showDescriptionButton} onClick={() => setShowDescription(!showDescription)}>
            <HideCloseEye hide={showDescription} />
          </button>
        </div>
        {showDescription && <p className={s.description}>{plan.definition.description}</p>}
      </div>

      <button className={s.detailButton} onClick={() => onPlanSelect(plan)}>Payoff Details </button>
    </div>
  )
};

function yearMonthDisplay(date: string) {
  const nMonths = dayjs().diff(date, 'months') * -1 + 1// the plus one is for how the diff of dayjs works. 
  const years = Math.floor(nMonths / 12)
  const months = years ? nMonths - (years * 12) : nMonths
  return years ? `${years} years and ${months} months` : `${months} months`
}