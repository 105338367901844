import debounce from 'debounce'
import { useStore } from "store"
//@ts-ignore
//eslint-disable-next-line import/no-webpack-loader-syntax
import PlanCalculationWorker from 'workers/PlansCalculation.worker'
import { refreshPlanDefinitionsDebtOrder } from "./planDefinitions"

const planCalculationWorker = new PlanCalculationWorker()
const processCalculationDb = debounce((debts, payments, planDefinitions) => {
  planCalculationWorker.postMessage({
    type: 'CALCULATE',
    debts,
    payments,
    planDefinitions
  })
}, 1000)

export function calculatePayoffSchedule(debts: Debt[], payments: Payment[], planDefinitions: PlanDefinition[]) {

  const refreshedPlanDefinitions = refreshPlanDefinitionsDebtOrder(planDefinitions, debts)
  const planProcessor = useStore.getState().planProcessor

  useStore.setState({ planProcessor: { ...planProcessor, status: 'PROCESSING' } })

  processCalculationDb(debts, payments, refreshedPlanDefinitions)


  planCalculationWorker.onmessage = function (e) {
    if (e.data.type === 'ERROR') {
      useStore.setState({ planProcessor: { ...planProcessor, status: 'ERROR', error: { message: e.data.message } } })
      return
    }

    useStore.setState({
      planProcessor: {
        ...planProcessor,
        status: 'SUCCESS',
        plans: e.data.plans,
        payoffCalculationMetadata: e.data.payoffCalculationMetadata
      }
    })
  }

  planCalculationWorker.onerror = function (e) {
    // e.preventDefault()

    useStore.setState({
      planProcessor: {
        ...planProcessor,
        status: 'ERROR',
        error: { code: 'UNKNOWN', message: e.message }
      }
    })
  }

}