import asModel from 'components/common/Model'
import Money from 'components/common/money'
import { Button, Dropdown, Input } from 'components/Forms'
import React, { useState } from 'react'
import { useStore } from 'store'
import { addCustomPlan, addStandardPlan } from 'store/planDefinitions'
import styleSynthesizer from 'styles'
import { v1 } from 'uuid'
import StandardPlans from '../StandardPlans'
import s from './newPlan.module.scss'
const ss = styleSynthesizer(s, 'NewPlan')

function NewPlan({ onClose }) {
  const [debts] = useStore(state => [state.debts])
  const [color, setColor] = useState('black')
  const [name, setName] = useState('Custom Plan')
  const [description, setDescription] = useState('')
  const [tab, setTab] = useState('CUSTOM')

  const orderOption = debts.map((_, index) => ({ _id: index, name: index + 1 }))
  const [debtOrder, setDebtOrder] = useState(orderOption.map(o => o._id))
  /**Utilize the index position in the debts array as an id 
   * debtsOrder will start by matching the debts, but will change as the user does
   */

  function handleOrderChange(currentPosition, moveToPosition) {
    const newOrder = [...debtOrder]
    newOrder.splice(currentPosition, 1)
    newOrder.splice(moveToPosition, 0, debtOrder[currentPosition])
    setDebtOrder(newOrder)
  }

  return (
    <div className={ss('container')}>
      <div className={s.headers}>
        <button className={tab === 'CUSTOM' ? s.tabActive : s.tab} onClick={() => setTab('CUSTOM')}>
          <h2 className={s.header}>Custom </h2>
        </button>
        <button className={tab === 'STANDARD' ? s.tabActive : s.tab} onClick={() => setTab('STANDARD')}>
          <h2 className={s.header}>Standard </h2>
        </button>
      </div>

      {tab === 'STANDARD'
        ? <div className={s.standardPlans}>
          <StandardPlans debts={debts} onAdd={addStandardPlan} />
        </div>
        : <>
          <div className={s.planInput}>
            <Input className={s.planName}
              label='Plan Name'
              type='text'
              maxLength={30}
              onChange={(e) => setName(e.target.value)}
              value={name} />
            <Input className={s.colorSelector}
              type='color'
              label='Color'
              onChange={(e) => setColor(e.target.value)}
              value={color} />
          </div>

          <Input className={s.description}
            label='Description'
            type='text'
            maxLength={500}
            onChange={(e) => setDescription(e.target.value)}
            value={description} />

          <p className={s.payoffOrder}>Payoff Order</p>
          <div className={s.debtList}>
            {debtOrder.map((rank, index) =>
              <div key={debts[rank].id} className={s.debt}>
                <Dropdown
                  className={s.rank}
                  options={orderOption}
                  emptyOption={false}
                  value={index}
                  onChange={(e) => handleOrderChange(index, e.target.value)
                  }
                />
                <div className={s.debtInfo}>
                  <p className={s.name}>{debts[rank].name}</p>
                  <p className={s.debtAmounts}>
                    <span>Balance: </span><Money>{debts[rank].amount}</Money> &nbsp;
                    <span>Apr:</span> {debts[rank].apr} &nbsp;
                    <span>Minimum Payment:</span> <Money>{debts[rank].minPayment}</Money>
                  </p>
                </div>
              </div>
            )}

          </div>
          <Button className={s.saveButton}
            onClick={() => {
              addCustomPlan({ type: 'CUSTOM', id: v1(), name, color, description, debtsOrder: debtOrder.map(o => debts[o].id) })
              onClose()
            }
            }>Save It</Button>
        </>}
    </div>
  )
};

export default asModel(NewPlan)


