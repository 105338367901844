import dayjs from 'dayjs'
import React from 'react'

export default function NMonth({ children: date }: { children: DateString | number }) {
  let dateText
  if (typeof date === "number") {
    dateText = dayjs().add(date, 'month').format('MMM, YYYY')
  } else if (typeof date === "string" ) {
    dateText = dayjs(date).format('MMM, YYYY')
  }

  
  return (
    <span className={'nMonth'}>
      {dateText}
    </span>
  )
};

