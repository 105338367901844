import ContrastColor from 'components/common/ContrastColor'
import React, { useMemo } from 'react'
import styleSynthesizer from 'styles'
import { getAllStandardPlans } from 'utils/planDefinitionFactory'
import s from './standardPlans.module.scss'
const ss = styleSynthesizer(s, 'StandardPlans')

type Props = {
  debts: Debt[]
  onAdd: (PlanType) => void
}

export default function StandardPlans({ debts, onAdd }: Props) {
  const plans = useMemo(() => getAllStandardPlans(debts), [debts])

  return (
    <div className={ss('container')}>
      {
        plans.map(plan =>
          <div key={plan.id} className={s.plan}>
            <p className={s.planName} style={{ backgroundColor: plan.color }}>{plan.name}</p>
            <p className={s.description}>{plan.description}</p>
            <button className={s.addButton} onClick={(e) => onAdd(plan.type)}>
              <ContrastColor hexColor={plan.color} lightColor='FFFFFF'>Add Plan</ContrastColor>
            </button>
          </div>
        )
      }
    </div>
  )
};
