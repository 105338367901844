import { usePasswordRest, userErrorMessage } from 'api/auth'
import AlertMessage from 'components/common/AlertMessage'
import BusinessLogo from 'components/common/BusinessLogo'
import { Button, Input } from 'components/Forms'
import useFormManagement from 'hooks/useFormManagement'
import { addSearchParam } from 'navigation'
import React from 'react'
import styleSynthesizer from 'styles'
import s from './forgotPassword.module.scss'
const ss = styleSynthesizer(s, 'ForgotPassword')

export default function ForgotPassword() {
  const reset = usePasswordRest()
  const form = useFormManagement({ email: '', password: '' }, handleSubmit)

  function handleSubmit(data) {
    reset.mutate(data.email)
    addSearchParam('auth', 'login').navTo({ type: 'INFO', message: 'Check your email for reset directions, than login' })
  }

  return (
    <div className={ss('container')}>
      <form className={s.form} onSubmit={form.onSubmit}>

        <BusinessLogo />
        <h2 className={s.header}>Password Reset</h2>
        <AlertMessage className={s.error}>{userErrorMessage(reset.error)}</AlertMessage>

        <fieldset className={s.fields}>
          <Input className={s.email} label='Email Address' type='text' name='email' value={form.data.email} onChange={form.onChange} />
        </fieldset>

        <div className={s.actions}>
          <Button className={s.button} onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={reset.isLoading} type='submit'>
            Send Reset Email
          </Button>
        </div>
      </form>
    </div>
  )
}
