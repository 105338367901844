import { EditIcon, UpArrow } from 'components/common/Icons'
import Money from 'components/common/money'
import styleSynthesizer from 'styles'
import { useStore } from 'store'
import s from './debtsList.module.scss'
import { moveDebtUpInDisplayOrder } from 'store/debts'
const ss = styleSynthesizer(s, 'DebtsList')

export default function DebtsList({ debts, onSelect }: { debts: Debt[]; onSelect: (debt: Debt) => void }) {
  let displayOrder = useStore(state => state.debtsDisplayOrder)

  if (debts.length === 0) return null

  if (!displayOrder || displayOrder.length !== debts.length) displayOrder = debts.map(d => d.id)
  const debtsInDisplayOrder = displayOrder.map(id => debts.find(d => d.id === id))

  return (
    <table className={ss('container')}>
      <thead>
        <tr>
          <th className={s.name}>Debt Name</th>
          <th>Amount</th>
          <th>Min Payment</th>
          <th>APR</th>
          <th>Interest</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {debtsInDisplayOrder.map(debt => (
          <tr className={s.debt} key={debt.id}>
            <td className={s.name}><span className={s.label}>Name:</span>{debt.name}</td>
            <td><span className={s.label}>Amount:</span><Money>{debt.amount}</Money></td>
            <td><span className={s.label}>Min Payment:</span><Money>{debt.minPayment}</Money></td>
            <td><span className={s.label}>APR:</span>{debt.apr}%</td>
            <td><span className={s.label}>Interest</span><Money>{debt.interest}</Money></td>
            <td className={s.edit}>
              <button onClick={() => onSelect(debt)}><EditIcon /></button>
              <button onClick={() => moveDebtUpInDisplayOrder(debt.id, displayOrder)}><UpArrow /></button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
