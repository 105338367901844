import { Input } from 'components/Forms'
import Money from 'components/Forms/Money'
import Percent from 'components/Forms/Percent'
import useFormManagement from 'hooks/useFormManagement'
import { nanoid } from 'nanoid'
import { useEffect, useRef, useState } from 'react'
import { removeDebt, upsertDebt } from 'store/debts'
import styleSynthesizer from 'styles'
import s from './debtForm.module.scss'
const ss = styleSynthesizer(s, 'DebtForm')

export default function DebtForm({ existingDebt, onSubmit }: { existingDebt: null | undefined | Debt, onSubmit: () => void }) {
  const [error, setError] = useState('')
  const debt = { ...getDefaultState(), ...existingDebt }
  const { data, onChange, ...form } = useFormManagement<Debt>(debt, handleApply)
  const debtNameRef = useRef<HTMLElement>()

  useEffect(() => {
    form.updateState(debt)
  }, [existingDebt])

  function getDefaultState() {
    return { id: nanoid(8), name: '', amount: 0, minPayment: 0, apr: 0 }
  }

  function handleApply(debt: Debt) {
    setError('')
    if (!debt.name) {
      // No other validation is needed.  Some coaches (Jen) like to zero things out.
      setError('A debt name is required')
      return
    }

    upsertDebt({
      ...debt,
      amount: +debt.amount,
      minPayment: +debt.minPayment,
      apr: +debt.apr
    })
    resetForm()
  }

  function handleRemove(id: string) {
    removeDebt(id)
    resetForm()
  }

  function resetForm() {
    debtNameRef.current.focus()
    form.updateState(getDefaultState())
    onSubmit()
  }

  return (
    <form className={ss('container')} onSubmit={form.onSubmit}>
      <p className={s.error}>{error}</p>
      <fieldset className={ss('formFields')}>
        <Input
          ref={debtNameRef}
          className={s.debtName}
          name='name'
          autoComplete='off'
          label={`Debt Name `}
          type='text'
          value={data.name}
          onChange={onChange}
        />

        <Money
          className={s.amount}
          autoComplete='off'
          label='Amount'
          name='amount'
          value={data.amount}
          onChange={onChange}
        />

        <Money
          className={s.minPayment}
          autoComplete='off'
          name='minPayment'
          label='Min Payment'
          value={data.minPayment}
          onChange={onChange}
        />

        <Percent
          className={s.apr}
          autoComplete='off'
          label='APR'
          name='apr'
          scale='any'
          value={data.apr}
          onChange={onChange}
        />
      </fieldset>
      <div className={s.actionButtons}>
        <button className={s.applyButton} disabled={!data.name}>{existingDebt ? 'Apply' : 'Add'}</button>
        {existingDebt && <button type='button' onClick={() => handleRemove(data.id)} className={s.removeButton}>Remove</button>}
      </div>
    </form>
  )
}
