import React from 'react';

/**
 * @param {object} p
 * @param {string} p.hexColor -Hex color
 * @param {string} [p.darkColor=dark] - A numeric value that will be converted to pretty display
 * @param {string} [p.lightColor=light] - A numeric value that will be converted to pretty display
 * @param {string} p.children - A numeric value that will be converted to pretty display
 */
type Props = {
	hexColor: string
	darkColor?: string
	lightColor?: string
	children: string | JSX.Element | JSX.Element[]
}
function ContrastColor({hexColor="#FFFFFF", darkColor='black', lightColor='white', children}: Props) {

  return ( 
    <span style={{color: getColor(hexColor, darkColor, lightColor) } }>
        {children}
    </span>
  );
};


export default ContrastColor;

export function getColor(hexColor:string = '#000000', dark:string = '#000000', light:string = '#ffffff') {
 	// If a leading # is provided, remove it
  if (hexColor.slice(0, 1) === '#') {
		hexColor = hexColor.slice(1);
	}

	// Convert to RGB value
	var r = parseInt(hexColor.substr(0,2),16);
	var g = parseInt(hexColor.substr(2,2),16);
	var b = parseInt(hexColor.substr(4,2),16);

	// Get YIQ ratio
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
	return yiq >= 128 ? dark : light
}