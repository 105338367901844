import React from 'react'
import __ from '../styles/inputs.module.css'


const Input = (props, ref) => {
  const { className, label, addon, prefix, value, helpText, ...otherProps } = props
  const addonStyle = addon ? __.addon : ''
  const prefixStyle = prefix ? __.prefix : ''

  return (
    <div className={`${className} input-container`}>
      <label className={`label ${__.label}`}>
        <span className={`label-text ${__.labelText}`}>{label}</span>
        <div className={`inputWrapper ${__.inputWrapper}`}>
          {prefix}
          <input aria-label={label} ref={ref} className={`input ${__.input} ${addonStyle} ${prefixStyle}`} value={value || ''} {...otherProps} />
          {addon}
        </div>
      </label>
      <p className={__.helpText}>{helpText}</p>
    </div>
  )
}

export default React.forwardRef(Input)