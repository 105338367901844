import React from 'react'
import s from './about.module.scss'
import { Helmet } from 'react-helmet'
import { Link } from '@reach/router'
import Header from 'components/Header'
import { defaultBusiness } from 'components/common/constants'

export default function About() {

  return (
    <React.Fragment>
      <Helmet>
        <title>About My Debt Free Coach</title>
      </Helmet>
      <div className={s.container}>
        <Header business={defaultBusiness} />
        <h1 className={s.title}>About</h1>
        <p>My Debt Free Coach is an advanced debt pay-off calculator built for financial coaches and advisors.</p>

        <p>My Debt Free Coach pay-off calculator lets you determine the best way to pay off your debts, so your money can work for you.  Simply enter all of your debts, and myDebtFree coach will show you which strategy is best for you;  Snowball, Avalanche, Snow Flurry, or a custom strategy of your own making.
          The advanced payment schedule allows you to see what is possible when you make additional payments monthly, yearly, once, or any combination.
        </p>
        <p>Future enhancements will include mortgage calculators, net-worth, and investments. </p>

        <h2 className={s.header}>Pay Off Strategies</h2>
        <h3 className={s.subject}>Avalanche</h3>
        <p className={s.definition}>Pay off all debts starting with the highest interest rate to the lowest (while paying minimum payments on the rest), carrying payments from one debt to the next.  This helps minimize the total out-of-pocket, however, it can be while before a debt eliminated.</p>

        <h3 className={s.subject}>Snowball</h3>
        <p className={s.definition}>Pay off all debts from the lowest balance to highest (while paying minimum payments on the rest), carrying payments from one debt to the next.  This supplies early wins, and you will see the debts fall of the list.</p>

        <h3 className={s.subject}>Snow Flurry</h3>
        <p className={s.definition}>Combines the Snowball and Avalanche plans into one big Snow Flurry by splitting your debts into 3 categories: ok, bad, and crazy.  If the APR is less than 6, it is ok, if it is over 16%,  it is crazy, otherwise, it falls into the bad category. You avalanche the categories and snowball the individual debts within each category.  Pay off all debts from the lowest balance to highest (while paying minimum payments on the rest) in the Crazy category, then do it again for bad and ok.</p>

        <h3 className={s.subject}>Creditors Paradise</h3>
        <p className={s.definition}>A baseline and shows how long it would take you to pay off all your debts making only the current minimum payment.  It does not consider your minimum payment will decrease over time as the debts are slowly paid down.  (any debt without a minimum payment will use an estimated minimum payment of 2.5% of the total loan amount)</p>
        <div className={s.action}>

        <Link className={s.link} to="/"><button className={s.linkButton}>Payoff Your Debt Now</button></Link>
</div>
      </div>
    </React.Fragment>
  )
}