import { LocationProvider, Router } from '@reach/router'
import ComponentError from 'components/common/ComponentError'
import LoadingOverlay from 'components/common/LoadingOverlay'
import IeMessage from 'components/IeMessage'
import React, { lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import About from './About'
import Auth from './Auth'
import Home from './Home'
import s from './scenes.module.scss'

const PageNotFound = lazy(() => import('./NotFound'))
const Business = lazy(() => import('./Business'))
const TestGeneration = lazy(() => import('tests/utils/TestGeneration'))


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    }
  }
})


export default function Scenes() {
  return (
    <ErrorBoundary fallback={<ComponentError componentName='Application' />}>
      <LocationProvider >
        <QueryClientProvider client={queryClient}>
          <IeMessage />
          <div className={s.application}>
            <Auth />
            <Suspense fallback={<LoadingOverlay message='Loading Page' />}>
              <Router>
                <PageNotFound path="/404" />
                <Business path="business/*" />
                <Home path="/" />
                <About path="/about" />
                <Home path=":businessName" />
                <PageNotFound default={true} />
                <TestGeneration path="/test/test" />
              </Router>
            </Suspense>
          </div>

          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            limit={1}
            theme='light' 
          />
          <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />

        </QueryClientProvider>
      </LocationProvider>
    </ErrorBoundary>
  )
}