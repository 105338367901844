import { useStore } from "store"
import { getAvalancheDefinition, getCreditorsParadise, getSnowballDefinition, getSnowFlurryDefinition } from "utils/planDefinitionFactory"


export function addStandardPlans(standardPlans: PlanType[]) {
  const planDefinitions = standardPlans.map(standardPlanName => createStandardPlan(standardPlanName))
  useStore.setState({ planDefinitions })
}

export function addStandardPlan(standardPlanName: PlanType) {
  const planDefinitions = [ ...useStore.getState().planDefinitions ]
  if(planDefinitions.find(p => p.type === standardPlanName)) return 

  const newPlan = createStandardPlan(standardPlanName)
  if(!newPlan) return
  
  planDefinitions.push(newPlan)
  useStore.setState({planDefinitions})
}

export function addCustomPlan(plan: PlanDefinition) {
  const planDefinitions = [ ...useStore.getState().planDefinitions, plan ]
  useStore.setState({planDefinitions})
}

export function removePlanDefinition(definition: PlanDefinition) {
  const planDefinitions = useStore.getState().planDefinitions.filter(d => d.id !== definition.id)
  useStore.setState({ planDefinitions })
}

export function refreshPlanDefinitionsDebtOrder(planDefinitions: PlanDefinition[], debts: Debt[]): PlanDefinition[] {
  return planDefinitions.map(pd => {
    if (pd.type === 'CUSTOM') {
      debts.forEach(debt => {
        if (pd.debtsOrder.includes(debt.id)) return
        pd.debtsOrder.push(debt.id)
      })
      return pd
    } else {
      return createStandardPlan(pd.type)
    }
  })
}

function createStandardPlan(planName: PlanType) {
    const debts = useStore.getState().debts
    if (planName === 'CREDITOR') return getCreditorsParadise(debts ?? [])
    if (planName === 'SNOWBALL') return getSnowballDefinition(debts ?? [])
    if (planName === 'SNOW_FLURRY') return getSnowFlurryDefinition(debts ?? [])
    if (planName === 'AVALANCHE') return getAvalancheDefinition(debts ?? [])
}