import PaymentSchedule from 'calculator/paymentSchedule'
import { EditIcon } from 'components/common/Icons'
import Money from 'components/common/money'
import dayjs from 'dayjs'
import React from 'react'
import { useStore } from 'store'
import s from './paymentsList.module.css'


type props = {
  onEditPayment: (payment: Payment) => void
}
function PaymentsList({ onEditPayment }: props): JSX.Element | null {
  const payments = useStore(state => state.payments)
  const paymentSchedule = new PaymentSchedule(payments)
  if (paymentSchedule && paymentSchedule.getPayments().length <= 0) return null

  return (
    <div className={s.container}>
      <div className={s.paymentHeader} >
        <span className={s.paymentItemHeader}>Payment</span>
        <span className={s.totalHeader}>Total</span>
        <span className={s.editHeader}></span>
      </div>
      {paymentSchedule?.getPaymentsByDate().map((payment, index) =>
        <div key={payment.id} className={s.paymentRow} data-testid={`payment-row-item-${index}`}>
          <PaymentItem payment={payment} />
          <span className={s.total}><Money>{payment.total}</Money></span>
          <button className={s.edit} onClick={() => onEditPayment(payment)}><EditIcon /></button>
        </div>
      )}
    </div>
  )
};


function PaymentItem({ payment }: { payment: Payment }) {
  return (
    <div className={s.paymentItem}>
      {dayjs().month(+payment.month - 1).format('MMM')}, {payment.year}
      &nbsp;<Money>{payment.amount}</Money>
      &nbsp;<span className={s.frequency}>{userFrequency(payment.frequency)}</span>
    </div>
  )
}

function userFrequency(frequency: string) {
  if (frequency === 'ONE_TIME') return 'once'
  if (frequency === 'PER_MONTH') return 'per month'
  if (frequency === 'PER_YEAR') return 'per year'
}

export default PaymentsList