import React from 'react'
import styleSynthesizer from 'styles'
import PlanAmortization from '../PlanAmortization'
import PlanChart from '../PlanChart'
import s from './planDetails.module.scss'


const ss = styleSynthesizer(s, 'PlanDetails')

export default function PlanDetails() {

  return (
    <div className={ss('container')}>
      <PlanChart />
      <PlanAmortization />
    </div>
  )
};


