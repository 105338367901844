import { useStore } from "store"


export function upsertPayment(payment: Payment) {
  const payments = [...useStore.getState().payments ]
  const index = payments.findIndex(p => p.id === payment.id)
  index === -1 ? payments.push(payment): payments[index] = payment
  useStore.setState({payments})
}

export function removePayment(id: string) {
  const payments = useStore.getState().payments.filter(p => p.id !== id)
  useStore.setState({payments})
}


