import React from 'react';
import s from './planPlaceholder.module.scss';
import styleSynthesizer from 'styles'
const ss = styleSynthesizer(s, 'PlanPlaceholder')

/**
 * @param {object} p 
 * @param {Business} p.business
 */
export default function PlanPlaceholder({business}) {
  return ( 
    <div className={ss('container')}>
      <q className={s.quote}>{business.quote}</q>
      <cite className={s.bibleLocation}>{business.quoteCitation}</cite>
    </div>
  );
};