import { navigate } from "@reach/router"
import lg from "utils/logger"

const end = (path) => ({
  url: () =>  path,
  fullUrl: () => window.location.protocol + '//' + window.location.host + path,
  navTo: (state?: NavigationState ) => {
    lg.info('navigation/navTo', path, 'navigating to path')
    return navigate(path, { state })
  },
  isCurrentPath: (path) => {
    return path === window.location.pathname
  },
})

const business = (path) => ({
  settings: () => end(`${path}/settings`),
  account: () => end(`${path}/account`),
  customers: () => customers(`${path}/customers`),
})

const customers = (path) => ({
  landing: () => end(`${path}/`),
  customerManagement: (leadId) => end(`${path}/${leadId}`)
})
 
export const navBuilder = {
  home: () => end('/'),
  notFound: () => end('/404'),
  homeBusiness: (businessUrl) => end(`/${businessUrl}`),
  businessRegistration: () => end('/business/registration'),
  business: (businessId) => business(`/business/${businessId}`)
}

type SearchParam = 'calculationId' | 'auth'
type AuthValues = 'login' | 'signup' | 'forgotPassword'
export function addSearchParam(param: SearchParam, value: AuthValues | any = '', url?: string) {
  const params = new URLSearchParams(window.location.search)
  params.set(param, value)
  const path = url ? url : window.location.pathname 
  return end(path + '?' + params.toString())
}

export function removeSearchParam(param?: SearchParam) {
  const params = new URLSearchParams(window.location.search)
  if (param) params.delete(param)
  const values = []
  params.forEach(p => {
    values.push(p)
  })

  if (values.length === 0) return end(window.location.pathname)
  return end(window.location.pathname + '?' + params.toString())
}

export function getSearchParams() {
  const params = new URLSearchParams(window.location.search)
  return {
    calculationId: params.get('calculationId'),
    auth: params.get('auth') as AuthValues
  }
}