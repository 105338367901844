import { useStore } from "store"

export function setAmountDisplayType(amountDisplayType: AmountDisplayId){
  const userInteractions = useStore.getState().userInteractions
  useStore.setState({userInteractions: {...userInteractions, amountDisplayType}})
}

export function setSelectedPlan(selectedPlan: Plan){
  const userInteractions = useStore.getState().userInteractions
  useStore.setState({userInteractions: {...userInteractions, selectedPlanId: selectedPlan?.definition?.id}})
}