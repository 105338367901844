import Money from 'components/Forms/Money'
import dayjs from 'dayjs'
import useFormManagement from 'hooks/useFormManagement'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import { removePayment, upsertPayment } from 'store/payments'
import { Button, Dropdown, Input } from '../../Forms'
import s from './paymentForm.module.scss'


const months: { _id: string, month: string }[] = []
for (let x = 0; x <= 11; x++) {
  const month = dayjs().month(x)
  months.push({
    _id: month.format("MM"),
    month: month.format("MMM"),
  })
}

const years: { _id: string, year: string }[] = []
for (let x = 0; x <= 15; x++) {
  const year = dayjs().add(x, 'year').format('YYYY')
  years.push({ _id: year, year })
}

const frequency = [
  { _id: 'ONE_TIME', name: 'one time' },
  { _id: 'PER_MONTH', name: 'per month' },
  { _id: 'PER_YEAR', name: 'per year' }
]

const paymentTemplate = {
  id: nanoid(8),
  month: dayjs().add(1, 'months').format('MM'),
  year: dayjs().format('YYYY'),
  amount: 0,
  frequency: 'PER_MONTH'
}

type PaymentFormProps = {
  paymentToEdit: Payment
}
export default function PaymentForm({ paymentToEdit }: PaymentFormProps) {
  const payment = paymentToEdit ? paymentToEdit : paymentTemplate
  const { data, ...form } = useFormManagement(payment, (data) => {
    upsertPayment(data)
    resetForm()
  })

  const [mode, setMode] = useState(paymentToEdit ? 'EDIT' : 'CREATE')
  useEffect(() => {
    if (paymentToEdit) {
      form.updateState(paymentToEdit)
      setMode('EDIT')
    }
  }, [paymentToEdit])

  function resetForm() {
    form.updateState({ ...data, id: nanoid(8), amount: 0 })
    setMode('CREATE')
  }

  function handleRemovePayment() {
    removePayment(data.id)
    resetForm()
  }
  return (
    <form className={s.form} onSubmit={form.onSubmit}>
      <fieldset className={s.fields}>
        <span className={s.storyLine}>Starting</span>
        <Dropdown data-testid='month'
          className={s.month}
          label='Month Selection'
          options={months}
          optionId='_id'
          optionName='month'
          name='month'
          emptyOption={false}
          value={data.month}
          onChange={form.onChange} />

        <span className={s.storyLine}>of</span>
        <Dropdown
          className={s.year}
          label='Year Selection'
          options={years}
          optionId='_id'
          optionName='year'
          name='year'
          emptyOption={false}
          value={data.year}
          onChange={form.onChange} />

        <span className={s.storyLine}>add</span>
        <Money data-testid='amount'
          label='Amount Field'
          className={s.amount}
          name='amount'
          value={data.amount}
          onChange={form.onChange} />

        <span className={s.storyLine}>dollars</span>
        <Dropdown data-testid='frequency'
          label='Frequency Selection'
          className={s.frequency}
          options={frequency}
          optionId='_id'
          name='frequency'
          value={data.frequency}
          onChange={form.onChange} />
        <span className={s.storyLine}>to my debt.</span>

      </fieldset>
      <div className={s.actionButtons}>

        {mode === 'EDIT' &&
          <Button
            className={s.removeButton} onClick={handleRemovePayment} >
            Remove
          </Button>
        }

        <Button className={s.submitButton} type="submit" onClick={form.onSubmit} disabled={!data.amount}>
          {mode === 'EDIT' ? 'Apply' : 'Add'}
        </Button>
      </div>

    </form>
  )
}