import { Link } from "@reach/router"

export default function CallToAction({ business, className }: { business: BusinessPublic, className: string }) {
  const LinkPart = business?.callToActionLink?.includes('http') || business?.callToActionLink?.includes('https')
    ? <a href={business.callToActionLink}>{business.callToActionLinkText}</a>
    : <Link to={business.callToActionLink || '/'}> {business.callToActionLinkText} </Link>

  return (
    <p className={className}>
      {business.callToActionMessage} {LinkPart} {business.callToActionSecondaryMessage}
    </p>
  )
}