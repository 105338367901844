import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { hydrate, render } from 'react-dom'
import App from './scenes'
import reportWebVitals from './reportWebVitals'
import './styles/reset.css'
import './styles/tool-tip.css'
import './styles/variables.css'
import './styles/global.module.css'
import './styles/scrollbar.module.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'



const rootElement = document.getElementById("root")
const appLoading = document.getElementById('application-loading')
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}
appLoading.remove()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//TODO: https://create-react-app.dev/docs/measuring-performance/
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('@axe-core/react')
//   axe(React, render, 1000, undefined, { exclude: [ [ 'grammarly-desktop-integration' ] ] })
// }