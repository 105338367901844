import { Link, useLocation } from '@reach/router'
import { useLogin, userErrorMessage } from 'api/auth'
import AlertMessage from 'components/common/AlertMessage'
import BusinessLogo from 'components/common/BusinessLogo'
import { Button, Input } from 'components/Forms'
import useFormManagement from 'hooks/useFormManagement'
import { addSearchParam, removeSearchParam } from 'navigation'
import React, { useEffect } from 'react'
import styleSynthesizer from 'styles'
import s from './login.module.scss'
const ss = styleSynthesizer(s, 'Login')

export default function Login() {
  const location = useLocation() as LocationWithState
  const login = useLogin()
  const form = useFormManagement({ email: '', password: '' }, login.mutate)
  useEffect(() => { login.isSuccess && removeSearchParam('auth').navTo() }, [login.isSuccess])
  useEffect(() => {
    if (login.isLoading && location.state?.message) location.state.message = ''
  }, [login.isLoading])

  return (
    <div className={ss('container')}> 
      <form className={s.form} onSubmit={form.onSubmit}>
        <BusinessLogo/>

        <h2 className={s.header}>Login</h2>
        <AlertMessage className={location.state?.type === 'INFO' ? s.infoMessage : s.errorMessage}>{location.state?.message}</AlertMessage>
        <AlertMessage className={s.error}>{userErrorMessage(login.error)}</AlertMessage>

        <fieldset className={s.fields}>
          <Input className={s.email} label='Email Address' type='text' name='email' value={form.data.email} onChange={form.onChange} />
          <Input className={s.password} label='Password' type='password' name='password' value={form.data.password} onChange={form.onChange} />
        </fieldset>

        <div className={s.actions}>
          <Button className={s.button} onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={login.isLoading} type='submit'>
            Login
          </Button>
        </div>
      </form>
      <p className={s.message}>
        <Link className={s.passwordReset} to={addSearchParam('auth', 'forgotPassword').url()}>
          Forgot Password
        </Link>
      </p>
      <p className={s.message}>
        Don't have an account? <Link className={s.passwordReset} to={addSearchParam('auth', 'signup').url()}> Sign Up </Link>
      </p>
    </div>
  )
}
