import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from 'chart.js'
import dayjs from 'dayjs'
import { sort } from 'fast-sort'
import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { useStore } from 'store'
import { penniesToMoney } from 'utils/money'
import __ from './planChart.module.css'
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


function PlansOverviewChart() {
  const {plans, amountDisplayType} = useStore(state => ({
    plans: state.planProcessor.plans,
    amountDisplayType: state.userInteractions.amountDisplayType
  }))
  const sortedPlans = sort<Plan>(plans).asc(p => p.summary.debtFreeDate)
  const longestPayoff = sortedPlans.pop()

  const datasets = useMemo(() => {
    return plans.map(plan => ({
      label: plan.definition.name,
      backgroundColor: plan.definition.color,
      data: plan.snapshots.map(s => amountDisplayType === 'I' ? penniesToMoney(s.debtTotals.amount) : penniesToMoney(s.debtTotals.totalPayed))
    }))

  }, [plans, amountDisplayType])

  const data = {
    labels: longestPayoff?.snapshots?.map(s => dayjs(s.date).format('MMM, YYYY')),
    datasets
  }

  return (
    <div className={__.container}>
      <Bar data={data}
        options={{ maintainAspectRatio: false }} />
    </div>
  )
};


export default PlansOverviewChart