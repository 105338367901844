import { useBusiness } from 'api/business'
import HeaderMessage from 'components/common/HeaderMessage'
import { useState } from 'react'
import { useStore } from 'store'
import styleSynthesizer from 'styles'
import DebtForm from './DebtForm'
import s from './debts.module.scss'
import DebtsList from './DebtsList'
const ss = styleSynthesizer(s, 'Debts')

export default function DebtsForm() {
  const business = useBusiness()
  const debts = useStore(state => state.debts)
  const [selectedDebt, setSelectedDebt] = useState<Debt>()

  return (
    <section aria-label='Your Debts' className={ss('container')}>
      <HeaderMessage title='Debts'> {business.data.debtsMessage} </HeaderMessage>
      <div className={ss('content')}>
        <DebtForm existingDebt={selectedDebt} onSubmit={() => setSelectedDebt(null)} />
        <div className={s.debtList}>
          <DebtsList debts={debts} onSelect={debt => setSelectedDebt(debt)} />
        </div>
      </div>
    </section>
  )
}

