import { calculateDebtTotals } from 'calculator/utils'
import   { create }   from 'zustand'
import  { devtools }  from 'zustand/middleware'

const initialState: Store = {
  payments: [],
  debts: [],
  debtsDisplayOrder: [],
  planDefinitions: [],
  debtTotals: {},
  planProcessor: {
    status: 'UNKNOWN',
  },
  userInteractions: { amountDisplayType: 'I' },
} as Store

//@ts-ignore
export const useStore = create<Store>(() => ( { ...initialState } ))

export function setPayoffCalculatorState(payoffCalculation: Calculation) {
  useStore.setState({
    payments: payoffCalculation?.payments || [],
    debts: payoffCalculation?.debts || [],
    planDefinitions: payoffCalculation?.planDefinitions || [],
    debtTotals: calculateDebtTotals(payoffCalculation?.debts || []),
    debtsDisplayOrder: payoffCalculation.debtsDisplayOrder || [],
    planProcessor: {
      status: 'UNKNOWN',
    },
  })
}

export function resetState() {
  useStore.setState({ ...initialState })
}