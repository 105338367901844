import React from 'react'
import __ from './headerMessage.module.css'


const HeaderMessage = ({title, children}) => {

  return ( 
    <div className={__.container}>
      <h2 className={__.title}>{title}</h2>
      <p className={__.message}>{children}</p>
    </div>
  );
};


export default HeaderMessage;