import { AVALANCHE, CREDITORS_PARADISE, SNOWBALL, SNOW_FLURRY } from 'definitions'
import { sort } from 'fast-sort'

export function getAvalancheDefinition(debts: Debt[]):PlanDefinition {
  return {
    id: 'avalanche-id',
    name: 'Avalanche',
    description: AVALANCHE,
    type: 'AVALANCHE',
    color: 'rgb(12 168 24 / 90%)', //Green
    debtsOrder: getDebtsSortOrder(debts, (d) => sort<Debt>(d).desc('apr'))
  }
}

export function getSnowballDefinition(debts):PlanDefinition {
  return {
    id: 'snow-ball-id',
    name: 'Snowball',
    description: SNOWBALL,
    type: 'SNOWBALL',
    color: 'rgb(77 93 246 / 90%)', //blue
    debtsOrder: getDebtsSortOrder(debts, (d) => sort<Debt>(d).asc('amount'))
  }
}

export function getSnowFlurryDefinition(debts):PlanDefinition {
  return {
    id: 'snow-flurry-id',
    name: 'Snow Flurry',
    description: SNOW_FLURRY,
    type: 'SNOW_FLURRY',
    color: 'rgb(244 67 54 / 90%)', //yellow
    debtsOrder: getDebtsSortOrder(debts, (d) => calculateSnowFlurry(d))
  }
}

export function getCreditorsParadise(debts):PlanDefinition {
  return {
    id: 'creditors-paradise-id',
    name: 'Creditors Paradise',
    description: CREDITORS_PARADISE,
    type: 'CREDITOR',
    color: 'rgb(168 37 85 / 90%)', //Red
    debtsOrder: getDebtsSortOrder(debts, (d) => d)
  }
}

export function getAllStandardPlans(debts) {
  return [
    getAvalancheDefinition(debts),
    getSnowballDefinition(debts),
    getSnowFlurryDefinition(debts),
    getCreditorsParadise(debts)
  ]
}

/**
 * @param {Debt []} debts
 */
function calculateSnowFlurry(debts) {
  const ok = sort<Debt>(debts.filter(d => d.apr < 6)).asc('amount')
  const bad = sort<Debt>(debts.filter(d => d.apr >= 6 && d.apr < 16)).asc('amount')
  const crazy = sort<Debt>(debts.filter(d => d.apr >= 16)).asc('amount')
  const debtOrder = [ ...crazy, ...bad, ...ok ]
  return debtOrder
}


function getDebtsSortOrder(debts:Debt[], sortFn:(debts: Debt[]) => Debt[]) {
  const _sortDebts = sortFn(debts)
  const debtIds = _sortDebts.map(d => d.id)
  return debtIds
}
