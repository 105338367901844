import NMonth from 'components/common/nMonth'
import React from 'react'
import styleSynthesizer from 'styles'
import { penniesDisplay } from 'utils/money'
import s from './planDebtDetails.module.scss'
const ss = styleSynthesizer(s, 'PlanDebtDetails')

type Props = {
  planSnapshot: PlanSnapshot
  amountDisplayType: AmountDisplayId
}

export default function PlanDebtDetails({ planSnapshot, amountDisplayType }: Props) {
  return (
    <article className={ss('container')}>
   
      {planSnapshot.debtSnapshots.map((debt, index) =>
        <div key={index} className={s.row}>
          <p className={s.title}>{debt.name}</p>
          <div className={s.info}>
            <p className={s.month}><NMonth>{debt.date}</NMonth></p>
            <p className={s.amount}>{amountDisplayType === 'I' ? penniesDisplay(debt.totalInterest) : penniesDisplay(debt.totalPayed)}</p>
          </div>
        </div>
      )}
    </article>
  )
};

