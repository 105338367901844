import NMonth from 'components/common/nMonth'
import dayjs from 'dayjs'
import React from 'react'
import CsvDownload from 'react-json-to-csv'
import { useStore } from 'store'
import { penniesDisplay } from 'utils/money'
import s from './planDetails.module.css'


/**
 * @param {object} p 
 * @param {Plan} p.plan 
 */
function PlanAmortization() {
  const plan = useStore(state => {
    return state.planProcessor.plans.find(p => p.definition.id === state.userInteractions.selectedPlanId)
  })
  const { snapshots, summary, definition } = plan
  const debtNames = ['All Debts', ...summary.snapshot.debtSnapshots.map(debt => debt.name)]

  const csvData = snapshots.map(s => ({
    month: s.date,
    total_balance: penniesDisplay(s.debtTotals.amount),
    total_interest: penniesDisplay(s.debtTotals.interest),
    total_payment: penniesDisplay(s.debtTotals.payed),
    total_payed: penniesDisplay(s.debtTotals.totalPayed),
    total_payed_interest: penniesDisplay(s.debtTotals.totalInterest),
    ...s.debtSnapshots.reduce((nd, d) => ({
      ...nd,
      [d.name + "_balance"]: penniesDisplay(d.amount),
      [d.name + "_interest"]: penniesDisplay(d.interest),
      [d.name + "_payment"]: penniesDisplay(d.payed),
      [d.name + "_total_payed"]: penniesDisplay(d.totalPayed),
      [d.name + "_total_interest"]: penniesDisplay(d.totalInterest),
    }), {})
  }))

  return (
    <div className={s.container}>
      <div className={s.header}>
        <h2 >{definition.name} Amortization Schedule</h2>
        <CsvDownload className={s.download} data={csvData} filename={`My Debt Free Coach ${dayjs().format('MM-DD-YYYY hh.mm a')}.csv`}>Download</CsvDownload>
      </div>
      <table className={s.table}>
        <thead>
          <tr className={s.groups} >
            <th></th>
            {debtNames.map(name => <th key={name} colSpan={5}>{name}</th>)}
          </tr>
          <tr className={s.columns} >
            <th className={s.month}>Month</th>
            <Columns debtNames={debtNames} />
          </tr>
        </thead>
        <tbody>
          {snapshots.map(snapshot =>
            <tr key={snapshot.date} className={s.detailRow}>
              <td className={s.firstInGroup}><NMonth>{snapshot.date}</NMonth></td>
              <DebtRow snapshot={snapshot.debtTotals} />
              {snapshot.debtSnapshots.map(snapshot => <DebtRow key={snapshot.id} snapshot={snapshot} />)}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
};


export default PlanAmortization

function Columns({ debtNames }) {
  return debtNames.map(name => (
    <React.Fragment key={name}>
      <th className={s.firstInGroup}>Balance</th>
      <th>Interest</th>
      <th>Payment</th>
      <th>Total Paid</th>
      <th className={s.lastInGroup}>Total Interest</th>
    </React.Fragment>
  ))
}

function DebtRow({ snapshot }: { snapshot: DebtSnapshot }) {
  if (snapshot.amount === 0) {
    return (
      <React.Fragment >
        <td className={`${s.emptyCell} ${s.firstInGroup}`}>-</td>
        <td className={s.emptyCell}>-</td>
        <td className={s.emptyCell}>-</td>
        <td className={s.emptyCell}>-</td>
        <td className={`${s.emptyCell} ${s.lastInGroup}`}>-</td>
      </React.Fragment>

    )
  }
  return (
    <React.Fragment >
      <td className={s.firstInGroup}>{penniesDisplay(snapshot.amount)}</td>
      <td>{penniesDisplay(snapshot.interest)}</td>
      <td>{penniesDisplay(snapshot.payed)}</td>
      <td>{penniesDisplay(snapshot.totalPayed)}</td>
      <td className={s.lastInGroup}>{penniesDisplay(snapshot.totalInterest)}</td>
    </React.Fragment>
  )
}