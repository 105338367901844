import { calculateDebtTotals, calculateMonthlyInterest } from "calculator/utils"
import { useStore } from "store"

export function upsertDebt(debt: Debt) {
  debt.interest = calculateMonthlyInterest(debt.amount * 100, debt.apr) / 100
  const debtsDisplayOrder = useStore.getState().debtsDisplayOrder

  const debts = [...useStore.getState().debts ]
  const index = debts.findIndex(d => d.id === debt.id)
  if(index === -1 ) {
    debts.push(debt)
    debtsDisplayOrder.push(debt.id)
  } else {
    debts[index] = debt
  }

  const debtTotals = calculateDebtTotals(debts)
  useStore.setState({debts, debtTotals, debtsDisplayOrder})
}

export function removeDebt(id: string) {
  const debts = useStore.getState().debts.filter(d => d.id !== id)
  const debtsDisplayOrder = useStore.getState().debtsDisplayOrder.filter(d => d !== id)
  const debtTotals = calculateDebtTotals(debts)
  useStore.setState({debts, debtTotals, debtsDisplayOrder})
}

export function moveDebtUpInDisplayOrder(id: string, debtsDisplayOrder: string[]) {
  const currentIndex = debtsDisplayOrder.findIndex(i => i === id)
  if(currentIndex === 0) return

  /*Swap items*/
  const temp = debtsDisplayOrder[currentIndex]
  debtsDisplayOrder[currentIndex] = debtsDisplayOrder[currentIndex -1]
  debtsDisplayOrder[currentIndex -1] = temp

  useStore.setState({debtsDisplayOrder: [ ...debtsDisplayOrder ]})
  
}
