import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useLayoutEffect } from 'react'
import __ from './model.module.scss'


export default function asModel(Component){

  return function ({ onClose, title, ...componentProps }) {
    useLayoutEffect(() => {
      const root = document.getElementById('root')
      const scrollY = window.scrollY
      root.classList.add('model-open')
      root.style.position = 'absolute'
      root.style.top = `-${scrollY}px`;

      return () => { 
        const root = document.getElementById('root')
        root.classList.remove('model-open')
        const scrollY = root.style.top
        root.style.position = ''
        root.style.top = ``;
        window.scrollTo(0, parseInt(scrollY ?? '0') * -1)
      }
    }, [])

    return (
      <div className={__.modelContainer}>
        <div className={__.modelContent}>
          <button type='button' className={__.closeButton} onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} /></button>
          <h3 className={__.modelTitle}>{title}</h3>
          <div className={__.modelChildren}>
            <Component onClose={onClose} {...componentProps} />
          </div>
        </div>
      </div>
    )
  }
}
