import Money from 'components/common/money'
import { useStore } from 'store'
import styleSynthesizer from 'styles'
import s from './debtTotals.module.scss'

const ss = styleSynthesizer(s, 'DebtTotals')

function DebtTotals() {
  const amount = useStore(state => state.debtTotals?.amount)
  const minPayment = useStore(state => state.debtTotals?.minPayment)
  const totalInterest = useStore(state => state.debtTotals?.interest)
  const numberOfDebts = useStore(state => state.debtTotals?.numberOfDebts || 0)

  if (!minPayment || !amount) return null

  return (
    <div className={ss('container')}>
      <p>You have <span className={s.amount}>{numberOfDebts}</span> debts totaling
        &nbsp;<span className={s.amount}><Money zero='0'>{amount}</Money></span></p>
      <p>Your total minimum monthly payment is <span className={s.amount}><Money zero='0'>{minPayment}</Money></span></p>
      <p>
        <span className={s.amount}>{Math.round((totalInterest / minPayment) * 101)}%</span>
        &nbsp;or&nbsp;
        <span className={s.amount}><Money zero='0'>{totalInterest}</Money></span>
        &nbsp;of your monthly payment is interest.
      </p>
    </div >
  )
};


export default DebtTotals