
export const TIMESTAMP_FORMAT = 'MMMM DD, YYYY h:mm a'
export const AMOUNT_DISPLAY_OPTIONS: { id: 'I' | 'P', name: 'Interest' | 'Out of Pocket' }[] = [
  {
    id: 'I',
    name: 'Interest'
  }, {
    id: 'P',
    name: 'Out of Pocket'
  }]

export const CONNECT_OPTIONS = [
  { _id: 'PHONE', name: 'Phone' },
  { _id: 'EMAIL', name: 'Email' },
  { _id: 'TEXT', name: 'Text' },
  { _id: 'MAIL', name: 'Mail' },
  { _id: 'OTHER', name: 'Other' }
]

export const SOURCES = [
  { id: 'debtfree', weight: 90, name: 'debt free coach', description: 'You Debt Free.Coach leads', color: '#ddce00' },
  { id: 'EaF440', weight: 80, name: 'referral', description: 'Leads received from a referral', color: '#B5057D' },
  { id: 'RwtcuY', weight: 70, name: 'online', description: 'Leads received thru online solutions like social media or search engine', color: '#744700' },
]

export const SOURCE_EVENTS = [
  { id: 'signup', weight: 90, name: 'sign up', description: 'Debt free coach sign up events', color: '#80007F' },
  { id: 'BoD9Nm', weight: 10, name: 'social media', description: 'Leads received from a conversation', color: '#7F8000' }
]

export const TAGS = [
  { id: 'hIQEAc', weight: 90, name: 'ideal', description: 'My ideal client', color: '#31319E' },
  { id: 'qkW6kz', weight: 80, name: 'pro bono', description: 'Free or reduced rate', color: '#A03131' },
]

export const STATUSES = [
  { id: 'lead-id', weight: 90, name: 'lead', description: 'An unqualified contact, you have not made contact', color: '#6A329F' },
  { id: 'prospect-id', weight: 80, name: 'prospect', description: 'A qualified contact, they know who you are and what you do', color: '#9F3432' },
  { id: 'client-id', weight: 70, name: 'client', description: 'Active client', color: '#007600' },
  { id: 'archived-client-id', weight: 1, name: 'archived client', description: 'Archive Client', color: '#696969' },
  { id: 'archived-id', weight: 1, name: 'archived', description: 'Any none active lead, client, etc.', color: '#696969' },
]

export const STATUS_IDS = {
  'lead-id': 'hgPJozzhHq',
  'prospect-id': 'vAbwVEZ6fz',
  'client-id': 'uJWgiD5j1d',
  'archived-client-id': 'uJWnVEZhHq',
  'archived-id': 'o8vn38EQJ2',
}

export const defaultBusiness: BusinessPublic = {
  businessId: 'my-debt-free-coach-id',
  businessUrl: 'DebtFree.coach',
  siteTitle: 'Debt Free Payoff Calculator',
  businessName: 'My Debt Free Coach LLC',
  tagLine: 'There is no need to be stressed about debt. With focus, dedication, and accountability, you can be debt free!',
  logoUrl: '/icons/ms-icon-150x150-progressive.jpeg',
  website: '/',

  callToActionMessage: 'Love the site, want to use it with your business?',
  callToActionLink: '/business/registration',
  callToActionLinkText: 'Sign Up Now!',
  callToActionSecondaryMessage: 'with My Debt Free Coach',

  paymentScheduleMessage: `Minimum payments are not enough. Creditors want you to be in debt. Don't let them! Take that extra money and secure your freedom.`,
  strategiesMessage: `How you payoff your debt makes a difference, but it's not always about the money. Remember personal finances are 80% behavior 20% math.  So look for some quick wins without breaking the bank.`,
  debtsMessage: `List all your debts, and we can tell you how long it will take to pay them off.`,
  quote: `The rich rule over the poor, and the borrower is the slave to the lender.`,
  quoteCitation: `Proverbs 22:7`,
  defaultStandardPlans: ['CREDITOR', 'SNOWBALL', 'SNOW_FLURRY', 'AVALANCHE'],

  headerBackgroundColor: '#f3d503',
  headerTextColor: '#000000',
  footerBackgroundColor: '#8c1ca8',
  footerTextColor: '#ffffff',
  modelTitleBackgroundColor: '#8c1ca8',
  modelTitleTextColor: '#ffffff',
  sectionHeaderMessageColor: '#8c1ca8',
  inputFocusColor: '#ddce00',
  labelColor: '#040a76',
  debtTotalsMessageNumberColor: '#8c1ca8',
  quoteColor: '#747474',
  quoteCitationColor: '#747474',
  buttonBackgroundColor: '#008080',
  buttonTextColor: '#ffffff',
}


export const stateList = [
  {
    "name": "Alabama",
    "abbreviation": "AL"
  },
  {
    "name": "Alaska",
    "abbreviation": "AK"
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "name": "California",
    "abbreviation": "CA"
  },
  {
    "name": "Colorado",
    "abbreviation": "CO"
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "name": "Delaware",
    "abbreviation": "DE"
  },
  {
    "name": "Florida",
    "abbreviation": "FL"
  },
  {
    "name": "Georgia",
    "abbreviation": "GA"
  },
  {
    "name": "Guam",
    "abbreviation": "GU"
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "name": "Idaho",
    "abbreviation": "ID"
  },
  {
    "name": "Illinois",
    "abbreviation": "IL"
  },
  {
    "name": "Indiana",
    "abbreviation": "IN"
  },
  {
    "name": "Iowa",
    "abbreviation": "IA"
  },
  {
    "name": "Kansas",
    "abbreviation": "KS"
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "name": "Maine",
    "abbreviation": "ME"
  },
  {
    "name": "Maryland",
    "abbreviation": "MD"
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "name": "Michigan",
    "abbreviation": "MI"
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "name": "Missouri",
    "abbreviation": "MO"
  },
  {
    "name": "Montana",
    "abbreviation": "MT"
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "name": "Nevada",
    "abbreviation": "NV"
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "name": "New York",
    "abbreviation": "NY"
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "name": "Ohio",
    "abbreviation": "OH"
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "name": "Oregon",
    "abbreviation": "OR"
  },
  {
    "name": "Palau",
    "abbreviation": "PW"
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "name": "Texas",
    "abbreviation": "TX"
  },
  {
    "name": "Utah",
    "abbreviation": "UT"
  },
  {
    "name": "Vermont",
    "abbreviation": "VT"
  },
  {
    "name": "Virginia",
    "abbreviation": "VA"
  },
  {
    "name": "Washington",
    "abbreviation": "WA"
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY"
  },
  {
    "name": 'Not Listed',
    "abbreviation": "NL"
  }
]