import { useLoggedInUser } from 'api/auth'
import { useBusiness } from 'api/business'
import { useCalculation, useSaveCalculation } from 'api/calculations'
import { Button } from 'components/Forms'
import { addSearchParam } from 'navigation'
import { useEffect } from 'react'
import styleSynthesizer from 'styles'
import s from './saveButton.module.scss'
const ss = styleSynthesizer(s, 'SaveButton')

export default function SaveButton() {
  const user = useLoggedInUser()
  const saveCalculation = useSaveCalculation()
  const business = useBusiness()
  const calculation = useCalculation()

  useEffect(() => {
    if (saveCalculation.isSuccess) addSearchParam('calculationId', saveCalculation.data?.calculationId).navTo()
  }, [saveCalculation.isSuccess, saveCalculation?.data?.calculationId])


  function handleSave() {
    if (!user?.uid) return addSearchParam('auth', 'login').navTo({ type: 'ERROR', message: 'Sorry, but you must be logged in to save your calculation.' })
    saveCalculation.mutate({ calculation: calculation.data, user, business: business.data })
  }


  return (
    <div className={ss('container')}>
      <Button className={s.saveButton} onClick={handleSave} isBlocking={saveCalculation.isLoading || calculation.isLoading}>Save</Button>
    </div>
  )

}