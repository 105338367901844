//From W3C Specification: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address 
// eslint-disable-next-line
const emailRx = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
// eslint-disable-next-line
const validFreeFromTextRx = /^[\w\s:\-#$,&'@+=_()!|\.]*$/
// eslint-disable-next-line
const phoneNumberRx = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
// eslint-disable-next-line
const numbersRx = /^[0-9]*$/
// eslint-disable-next-line
const urlRx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

export const validFreeFromTextCharacters = "contains an invalid special character, only the following are allowed: - # $ & ' @ + = _ ( ) ! | . :"

export function isPhoneNumber(number) {
  return phoneNumberRx.test(number)
}
export function isEmail(email) {
  return emailRx.test(email)
}

export function isUrl(url) {
  return urlRx.test(url)
}

export function isDatabaseKey(key) {
  if (!key) return false
  return numbersRx.test(key)
}

export function isValidFreeFormText(text) {
  return validFreeFromTextRx.test(text)
}
