import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
  Tooltip, Filler
} from 'chart.js'
import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { useStore } from 'store'
import { penniesToMoney } from 'utils/money'
import __ from './planChart.module.css'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);


function PlanChart() {
  const plan = useStore(state => {  
    return state.planProcessor.plans.find(p => p.definition.id === state.userInteractions.selectedPlanId)
  })
  
  const data = useMemo(() => ({
    labels: plan.snapshots.map(s => s.date),
    datasets: [ {
      label: 'Interest',
      backgroundColor: 'rgb(12 168 24 / 80%)',
      fill: true,
      data: plan.snapshots.map(s => penniesToMoney(s.debtTotals.interest))
    }, {
      label: 'Debt Amount',
      backgroundColor: 'rgb(168 37 85 / 60%)',
      fill: true,
      data: plan.snapshots.map(s => penniesToMoney(s.debtTotals.amount))
    }, {
      label: 'Monthly Payment',
      backgroundColor: 'rgb(168 158 45 / 80%)',
      fill: true,
      data: plan.snapshots.map(s => penniesToMoney(s.debtTotals.payed))
    }, {
      label: 'Payed',
      backgroundColor: 'rgb(140 28 168 / 50%)',
      fill: true,
      data: plan.snapshots.map(s => penniesToMoney(s.debtTotals.totalPayed))
    } ]
  }),  [ plan ] )

  return (
    <div className={__.container}>
      <Line data={data}
        // width={800}
        // height={405}
        options={{ maintainAspectRatio: false }} />
    </div>
  )
};


export default PlanChart