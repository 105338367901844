import { ValidationError } from "components/common/Error"
import { isPhoneNumber, isValidFreeFormText, validFreeFromTextCharacters } from "./commonValidations"

export function validateBusinessRegistrationInput(b: BusinessInfo) {

  if(!b.businessName) throw new ValidationError('Business name is required')
  if(!isValidFreeFormText) throw new ValidationError('Business name ' + validFreeFromTextCharacters) 
  if(b.businessName.length > 150 || b.businessName.length < 3) throw new ValidationError('Business name must be between 3 and 150 characters')

  if(!b.phone) throw new ValidationError('Phone number is required')
  if(!isPhoneNumber(b.phone)) throw new ValidationError('Phone number must be in xxx-xxx-xxxx format')

  if(!b.state) throw new ValidationError('State is required')

  if(!b.zipCode) throw new ValidationError('Zip code is required')
  if(!b.zipCode.match(/^[0-9]{5}(?:-[0-9]{4})?$/)) throw new ValidationError('Invalid zip code, please enter a 5 digit ##### or 9 digit #####-#### zip code')

  if(!b.addressLine1) throw new ValidationError('Address line 1 is required')
  if(b.addressLine1 && !isValidFreeFormText(b.addressLine1)) throw new ValidationError('Address line 1 ' + validFreeFromTextCharacters)
  if(b.addressLine1 && b.addressLine1.length > 150) throw new ValidationError('Address line 1 can not be more than 150 characters')

  if(b.addressLine2 && !isValidFreeFormText(b.addressLine2)) throw new ValidationError('Address line 2 ' + validFreeFromTextCharacters)
  if(b.addressLine2 && b.addressLine2.length > 150) throw new ValidationError('Address line 2 can not be more than 150 characters')
}