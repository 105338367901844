import { faCalendarAlt, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from '@reach/router'
import { useBusiness } from 'api/business'
import { AMOUNT_DISPLAY_OPTIONS } from 'components/common/constants'
import { Button, Dropdown } from 'components/Forms'
import React, { useEffect, useState } from 'react'
import { useStore } from 'store'
import { calculatePayoffSchedule } from 'store/payOffCalculator'
import { addStandardPlans } from 'store/planDefinitions'
import { setAmountDisplayType } from 'store/userInteractions'
import styleSynthesizer from 'styles'
import { shallow } from 'zustand/shallow'
import HeaderMessage from '../common/HeaderMessage'
import NewPlan from './NewPlan'
import PlanPlaceholder from './PlanPlaceholder'
import s from './plans.module.scss'
import PlansDisplay from './PlansDisplay/Index'
const ss = styleSynthesizer(s, 'Plans')

export default function Plans() {
  const { businessName } = useParams()
  const business = useBusiness(businessName)
  const [showNewPlan, setShowNewPlan] = useState(false)
  const { debts, planDefinitions, payments, status, errorMessage, planCount } = useStore(state => ({
    status: state.planProcessor.status,
    errorMessage: state.planProcessor.error?.message,
    planCount: state.planProcessor.plans?.length,
    debts: state.debts,
    payments: state.payments,
    planDefinitions: state.planDefinitions,
  }), shallow)

  useEffect(() => {
    if (!planDefinitions?.length) addStandardPlans(business.data.defaultStandardPlans)
  }, [JSON.stringify(business.data.defaultStandardPlans), planDefinitions])

  useEffect(() => {
    if (debts.length > 0) calculatePayoffSchedule(debts, payments, planDefinitions)
  }, [debts, planDefinitions, payments])

  if (status === 'ERROR' && debts.length > 0)
    return (
      <article className={ss('error')}>
        <HeaderMessage title='Strategies'>{business.data.strategiesMessage}</HeaderMessage>
        <div className={s.errorMessage}>
          <FontAwesomeIcon className={s.calendar} icon={faCalendarAlt} /><p>{errorMessage}</p>
        </div>
      </article>
    )

  if (status === 'SUCCESS' || (status === 'PROCESSING' && debts.length > 0 && planCount > 0)) return (
    <React.Fragment>
      <div className={ss('container')}>
        <HeaderMessage title='Strategies'>{business.data.strategiesMessage}</HeaderMessage>
        <div className={ss('actionBar')} >
          <Button className={s.createNewPlan} onClick={() => setShowNewPlan(true)}>
            Create Strategy
          </Button>
          <AmountDisplaySelector />
        </div>
        <ProcessingOverlay status={status} planCount={planCount} />
        <PlansDisplay />

      </div>
      {showNewPlan && <NewPlan title='New Strategies' onClose={() => setShowNewPlan(false)} />}
    </React.Fragment>
  )


  return (
    <div className={ss('placeholderContainer')}>
      <ProcessingOverlay status={status} planCount={planCount} />
      <PlanPlaceholder business={business.data} />
    </div>
  )
};

function ProcessingOverlay({ status, planCount }) {
  if (status === 'PROCESSING')
    return (
      <div className={ss('processingOverlay')}>
        <div className={s.content}>
          <FontAwesomeIcon className={s.icon} icon={faDollarSign} />
          <p className={s.message}>{planCount > 0 ? 'Reprocessing Debt Free Date' : 'Processing Debt Free Date'}</p>
        </div>
      </div>
    )

  return null
}

function AmountDisplaySelector() {
  const amountDisplayType = useStore(state => state.userInteractions.amountDisplayType)
  return <Dropdown
    label='Show Amounts by'
    className={s.amountOptions}
    options={AMOUNT_DISPLAY_OPTIONS}
    optionId='id'
    emptyOption={false}
    value={amountDisplayType}
    onChange={(e) => setAmountDisplayType(e.target.value)} />
}
