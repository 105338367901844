
/**
 * @param {object} classToCopy - the class instance to copy
 * @returns {object} 
 */
export function copyClassInstance(classToCopy) {
  return Object.assign( Object.create( Object.getPrototypeOf(classToCopy) ), classToCopy )
}

export function calculateDebtTotals(debts: Debt []){
  return debts.reduce((total, debt) => {
    return {
      amount: total.amount + debt.amount,
      minPayment: total.minPayment + debt.minPayment,
      interest: total.interest + debt.interest,
      numberOfDebts: total.numberOfDebts + 1
    }
  }, { amount: 0, minPayment: 0, interest: 0, numberOfDebts: 0 } as DebtTotal)
}

export function calculateMonthlyInterest(amount:number, apr:number):number {
  const interest = (apr / 100) / 12
  return Math.round(interest * amount)
}