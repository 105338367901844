import { Link } from '@reach/router'
import { userErrorMessage, useSignup } from 'api/auth'
import { useBusiness } from 'api/business'
import AlertMessage from 'components/common/AlertMessage'
import BusinessLogo from 'components/common/BusinessLogo'
import { Button, Input } from 'components/Forms'
import Checkbox from 'components/Forms/Checkbox'
import useFormManagement from 'hooks/useFormManagement'
import { addSearchParam, removeSearchParam } from 'navigation'
import React, { useEffect } from 'react'
import { useStore } from 'store'
import styleSynthesizer from 'styles'
import s from './signup.module.scss'
const ss = styleSynthesizer(s, 'Signup')

export default function Signup() {
  const signup = useSignup()
  const businessName = useStore(state => state.nav?.businessName)
  const business = useBusiness(businessName)
  const form = useFormManagement({
    fullName: '',
    email: '',
    password: '',
    isBusinessProspect: false,
  }, (data) => {
    signup.mutate({
      ...data,
      businessId: business.data.businessId,
      businessUrl: business.data.businessUrl,
    })
  })
  useEffect(() => { signup.isSuccess && removeSearchParam('auth').navTo() }, [signup.isSuccess])

  return (
    <div className={ss('container')}>
      <form className={s.form} onSubmit={form.onSubmit}>

        <BusinessLogo />
        <h2 className={s.header}>Sign Up</h2>

        <AlertMessage className={s.error}>{userErrorMessage(signup.error)}</AlertMessage>

        <fieldset className={s.fields}>
          <Input className={s.fullName} label='Full Name' type='text' name='fullName' value={form.data.fullName} onChange={form.onChange} />
          <Input className={s.email} label='Email Address' type='text' name='email' value={form.data.email} onChange={form.onChange} />
          <Input className={s.password} label='Password' type='password' name='password' value={form.data.password} onChange={form.onChange} />
          <Checkbox onChange={form.onChange} name='isBusinessProspect' value={form.data.isBusinessProspect} label='I am a Financial Coach and/or Advisor'></Checkbox>
        </fieldset>

        <div className={s.actions}>
          <Button className={s.button} onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={signup.isLoading} type='submit'>
            Sign Up
          </Button>
        </div>
        <p className={s.legal}>
          By proceeding, you consent to be contacted by email,
          from myDebtFree.Coach and others on financial-related matters.
        </p>
      </form>
      <p className={s.message}>
        Already have an account? <Link className={s.passwordReset} to={addSearchParam('auth', 'login').url()}> Login </Link>
      </p>
    </div>
  )
}
