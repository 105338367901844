import React from 'react'
import __ from '../styles/inputs.module.css'

/**
 * @param {object} p
 * @param {string=} p.label - Adds a label to the top of the input/drop down box
 * @param {any} p.value - current selection for the drop down
 * @param {Object.<*, string>} p.options - current selection for the drop down
 * @param {string} [p.optionId=_id] - key value for the options object
 * @param {string} [p.optionName=name] - current selection for the drop down
 * @param {string} [p.name] - name needed for on change
 * @param {boolean} [p.emptyOption=true] - determines if an empty option is displayed or not
 * @param {string} [p.className] - the class name that is applied to the select tag of the drop down
 * @param {function(any):void} [p.onChange] - onChange handler
 */
const SelectInput = ({label, value, options=[], optionId='_id', optionName='name', emptyOption=true, className, onChange, ...otherProps }) => {
  return (
    <div className={`${className} ${__.inputContainer} input-container` }>
      <label className={`${__.label} label`}>
        <span className={`label-text ${__.labelText}`}>{label}</span>
        <div className={__.inputWrapper}>
          { /* eslint-disable jsx-a11y/no-onchange */ }
          <select aria-label={label} className={`${__.dropDown} dropDown`} value={value || ''}  onChange={onChange} {...otherProps}>
            { emptyOption && <option value="" disabled></option> }
            {options.map((option) => 
              <option key={option[optionId]} className={__.dropDownValue} value={option[optionId]}>{option[optionName]}</option>
            )}
          </select>
        </div>
      </label>
    </div>
  )
}

export default SelectInput